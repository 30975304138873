<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3"></div>

      <div class="col-6 register-container">
        <center>
          <img
            :src="require('@/assets/FrontLogo.png')"
            alt="Logo"
            class="logo mb-2"
          />
        </center>
        <form @submit.prevent="register" class="register-form">
          <div class="row">
            <div class="col-6">
              <h4 class="d-flex justify-content-center font-weight-bold">
                Personal Information
              </h4>
              <div class="form-group">
                <label for="Fname">First Name:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="Fname"
                  required
                />
              </div>
              <div class="form-group">
                <label for="Mname">Middle Name:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="Mname"
                  required
                />
              </div>
              <div class="form-group">
                <label for="Lname">Last Name:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="Lname"
                  required
                />
              </div>
              <div class="form-group">
                <label for="phone">Phone:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="phone"
                  required
                />
              </div>
              <div class="form-group">
                <label for="regNumber">Registration Number:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="regNumber"
                  required
                />
              </div>
              <div class="form-group">
                <label for="photo">Photo:</label>
                <input
                  type="file"
                  class="form-control"
                  @change="onFileChange"
                  accept="image/*"
                />
              </div>
            </div>
            <div class="col-6">
              <h4 class="d-flex justify-content-center font-weight-bold">
                Account
              </h4>
              <div class="form-group">
                <label for="email">Email:</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  required
                />
              </div>
              <div class="form-group">
                <label for="password">Password:</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  required
                />
              </div>
              <h4 class="d-flex justify-content-center font-weight-bold mt-2">
                Security Questions
              </h4>
              <div class="form-group">
                <label for="sec1"
                  >Question 1: What is your favorite sports?</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="sec1"
                  required
                />
              </div>
              <div class="form-group">
                <label for="sec2"
                  >Question 2: What was the name of your first pet?</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="sec2"
                  required
                />
              </div>
              <div class="form-group">
                <label for="sec3"
                  >Question 3: In which city your were born?</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="sec3"
                  required
                />
              </div>
            </div>
          </div>
          <center>
            <button type="submit" class="btn btn-register mt-2">
              Register
            </button>
          </center>
        </form>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../config";
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      Fname: "",
      Mname: "",
      Lname: "",
      email: "",
      password: "",
      phone: "",
      account_type: "Admin",
      barangay: "DENR-San Isidro",
      regNumber: "",
      photo: "",
      sec1: "",
      sec2: "",
      sec3: "",
      loading: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];

      if (!file) {
        this.photo = null;
        return;
      }

      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please upload a valid image file (JPG, PNG).",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.photo = null;
        return;
      }

      const maxFileSize = 5 * 1024 * 1024;
      if (file.size > maxFileSize) {
        Swal.fire({
          title: "File Too Large",
          text: "The file size must be less than 5MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.photo = null;
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        this.photo = base64String.split(",")[1];
        this.photoPreview = base64String;
      };
      reader.readAsDataURL(file);
    },

    async register() {
      try {
        this.loading = true;

        Swal.fire({
          title: "Please wait...",
          text: "We are sending your otp in your email!",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await axios.post(`${API_URL}/register`, {
          Fname: this.Fname,
          Mname: this.Mname,
          Lname: this.Lname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          account_type: this.account_type,
          regNumber: this.regNumber,
          barangay: this.barangay,
          photo: this.photo,
          sec1: this.sec1,
          sec2: this.sec2,
          sec3: this.sec3,
        });

        this.loading = false;
        Swal.close();
        const router = useRouter();
        if ((this.successMessage = "You can now login.")) {
          Swal.fire({
            title: "Account successfully registered!",
            text: "Please enter the OTP sent to your email for verification.",
            icon: "success",
            confirmButtonText: "Okay",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/login");
            }
          });
        } else {
          Swal.fire({
            title: "Account not registered!",
            text: this.errorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        this.loading = false;
        Swal.close();

        this.errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An error occurred.";
        Swal.fire({
          title: "Error",
          text: this.errorMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>

<style>
.register-container {
  border-radius: 3px;
  height: auto;
  padding: 2rem;
}
.register-form .form-group {
  margin-bottom: 15px;
}

.register-form .d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register-form .btn-register {
  width: 50%;
  background-color: #325f62;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.modal-header {
  background-color: #325f62;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.modal-title {
  font-size: 1.25rem;
  color: white;
  text-align: center;
}

.modal-header .close {
  color: white;
}
</style>
