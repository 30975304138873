<template>
  <div class="container-fluid">
    <div class="row" style="margin-top: 6%">
      <div class="col-lg-2 col-md-2 col-sm-2"></div>

      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div class="logo-box text-center">
                  <img
                    :src="require('@/assets/FrontLogo.png')"
                    alt="Logo"
                    class="logo"
                  />
                </div>

                <div class="row d-flex justify-content-center">
                  <div class="col-lg-10 col-md-10 col-sm-10">
                    <form @submit.prevent="login" class="login-form">
                      <div class="form-group">
                        <input
                          type="email"
                          placeholder="Email"
                          v-model="email"
                          id="email"
                          class="LoginControl form-control"
                          required
                        />
                      </div>
                      <div class="form-group mt-2">
                        <input
                          type="password"
                          placeholder="Password"
                          v-model="password"
                          id="password"
                          class="LoginControl form-control"
                          required
                        />
                      </div>
                      <div class="form-group d-flex justify-content-end">
                        <a
                          href="#"
                          @click="ForgotPasswordModal"
                          class="forgot-password-link"
                          >Forgot Password?</a
                        >
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-login mt-2">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <img
                  :src="require('@/assets/FrontPage.png')"
                  alt="FrontPage"
                  class="FrontPage img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2"></div>
    </div>
  </div>

  <div
    class="modal fade"
    id="ForgotPassword"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ForgotPasswordLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <form @submit.prevent="ForgotPassword" class="forgot-form">
            <center>
              <h3 class="mb-1 mt-2 font-weight-bold">Forgot Password</h3>
            </center>

            <div class="form-group mt-2">
              <label for="email">Email:</label>
              <input
                type="email"
                class="ForgotControl form-control Forgot-Control"
                v-model="Forgotemail"
                required
              />
            </div>
            <h4 class="d-flex justify-content-center mt-2 font-weight-bold">
              Security Questions
            </h4>
            <div class="form-group">
              <label for="sec1"
                >Question 1: What is your favorite sports?</label
              >
              <input
                type="text"
                class="ForgotControl form-control"
                v-model="sec1"
                required
              />
            </div>
            <div class="form-group">
              <label for="sec2"
                >Question 2: What was the name of your first pet?</label
              >
              <input
                type="text"
                class="ForgotControl form-control"
                v-model="sec2"
                required
              />
            </div>
            <div class="form-group">
              <label for="sec3"
                >Question 3: In which city your were born?</label
              >
              <input
                type="text"
                class="ForgotControl form-control"
                v-model="sec3"
                required
              />
            </div>
            <div class="form-group mt-2">
              <label for="password">New Password:</label>
              <input
                type="password"
                class="ForgotControl form-control"
                v-model="Forgotpassword"
                required
              />
            </div>
            <center>
              <button type="submit" class="btn btn-primary mb-1">
                Reset Password
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="OTP"
    tabindex="-1"
    role="dialog"
    aria-labelledby="OTPLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <h2 class="mb-3 mt-2 font-weight-bold">Enter Verification Code</h2>
          <p>Your verification code is sent via email to</p>
          <strong
            ><p class="mb-4">{{ email }}</p></strong
          >

          <form id="otpForm" @submit.prevent="VerifyOTP">
            <div class="d-flex justify-content-center mb-3">
              <div class="otp-input-group">
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[0]"
                  @input="focusNext($event, 1)"
                />
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[1]"
                  @input="focusNext($event, 2)"
                />
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[2]"
                  @input="focusNext($event, 3)"
                />
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[3]"
                  @input="focusNext($event, 4)"
                />
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[4]"
                  @input="focusNext($event, 5)"
                />
                <input
                  type="text"
                  class="otp-input form-control"
                  maxlength="1"
                  v-model="OTP[5]"
                  @input="focusNext($event, 6)"
                />
              </div>
            </div>

            <div class="mb-3">
              <span v-if="timer > 0"
                >Resend OTP in: {{ minutes }}:{{
                  seconds < 10 ? "0" + seconds : seconds
                }}</span
              >
              <button
                type="button"
                class="btn btn-link"
                @click="resendOTP"
                v-if="timer === 0"
              >
                Resend OTP
              </button>
            </div>

            <button type="submit" class="btn btn-primary mb-2">
              Submit OTP
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { API_URL } from "../config";
import Swal from "sweetalert2";

export default {
  name: "Login",
  setup() {
    const email = ref("");
    const password = ref("");
    const Forgotemail = ref("");
    const Forgotpassword = ref("");
    const sec1 = ref("");
    const sec2 = ref("");
    const sec3 = ref("");
    const OTP = ref(["", "", "", "", "", ""]);
    const timer = ref(300);
    const minutes = ref(5);
    const seconds = ref(0);
    let timerInterval;
    const successMessage = ref("");
    const errorMessage = ref("");
    const router = useRouter();

    const checkEmailVerification = async (email) => {
      const response = await axios.post(`${API_URL}/check-email-verification`, {
        email: email,
      });
      return response.data.email_verified_at === null;
    };

    const login = async () => {
      try {
        const isEmailUnverified = await checkEmailVerification(email.value);

        if (isEmailUnverified) {
          OTPModalOpen();
          return;
        }

        const response = await axios.post(`${API_URL}/login`, {
          email: email.value,
          password: password.value,
        });

        // Store token and other user info in localStorage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("id", response.data.user.id);
        localStorage.setItem("Fname", response.data.user.Fname);
        localStorage.setItem("Lname", response.data.user.Lname);
        localStorage.setItem("account_type", response.data.user.account_type);

        // Set Axios Authorization header after login
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;

        const account_type = localStorage.getItem("account_type");

        if (account_type === "Admin") {
          Swal.fire({
            title: "Loading...",
            text: "Please wait while we redirect you.",
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          setTimeout(() => {
            router.push("/home").finally(() => {
              Swal.close();
            });
          }, 1000);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Access Denied",
            text: "Only admins can access this page. Please check your credentials and try again.",
            confirmButtonText: "Go to Login",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              router.push("/login");
            }
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Login Failed",
          text: "Invalid credentials. Please check your email (if verified) and password and try again.",
          confirmButtonText: "Retry",
          confirmButtonColor: "#3085d6",
        });
      }
    };

    const focusNext = (event, nextIndex) => {
      const value = event.target.value;
      if (value.length === 1 && nextIndex < 6) {
        document.querySelectorAll(".otp-input")[nextIndex]?.focus();
      }
    };

    const VerifyOTP = async () => {
      try {
        const otpCode = OTP.value.join("");

        const response = await axios.post(`${API_URL}/verifyOtp`, {
          email: email.value,
          otp: otpCode,
        });

        successMessage.value = response.data.message;

        if (
          successMessage.value ===
          "Email verified successfully. You can now login."
        ) {
          OTPModalClose();
          Swal.fire({
            title: "Email verified successfully!",
            text: "You can now log in.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        errorMessage.value =
          error.response?.data?.message ||
          error.message ||
          "An error occurred.";
        Swal.fire({
          title: "Error",
          text: errorMessage.value,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const startTimer = () => {
      timer.value = 300;
      timerInterval = setInterval(() => {
        if (timer.value > 0) {
          timer.value--;
          minutes.value = Math.floor(timer.value / 60);
          seconds.value = timer.value % 60;
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
    };

    const stopTimer = () => {
      clearInterval(timerInterval);
    };

    const resendOTP = async () => {
      stopTimer();
      startTimer();
      try {
        await axios.post(`${API_URL}/resendOtp`, { email: email.value });
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to resend OTP. Please try again.",
          icon: "error",
        });
      }
    };

    const ForgotPassword = async () => {
      try {
        Swal.fire({
          title: "Please wait...",
          text: "We're checking your details!",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await axios.post(`${API_URL}/password/reset`, {
          email: Forgotemail.value,
          password: Forgotpassword.value,
          sec1: sec1.value,
          sec2: sec2.value,
          sec3: sec3.value,
        });

        Swal.close();

        if (response.data.message === "Password reset successfully.") {
          successMessage.value = "Password reset successfully.";
          Swal.fire({
            title: "Password Reset Successfully!",
            text: "You can now log in.",
            icon: "success",
            confirmButtonText: "Go to Login",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              $("#ForgotPassword").modal("hide");
            }
          });
        } else {
          Swal.fire({
            title: "Sorry...",
            text: errorMessage.value,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.close();
        errorMessage.value =
          error.response?.data?.message ||
          error.message ||
          "An error occurred.";
        Swal.fire({
          title: "Error",
          text: errorMessage.value,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const ForgotPasswordModal = () => {
      $("#ForgotPassword").modal("show");
    };

    const ForgotPasswordClose = () => {
      $("#ForgotPassword").modal("hide");
    };

    const OTPModalOpen = () => {
      $("#OTP").modal("show");
    };

    const OTPModalClose = () => {
      $("#OTP").modal("hide");
    };

    onMounted(() => {
      startTimer();
    });

    onUnmounted(() => {
      stopTimer();
    });

    return {
      email,
      password,
      sec1,
      sec2,
      sec3,
      successMessage,
      errorMessage,
      login,
      ForgotPassword,
      ForgotPasswordModal,
      ForgotPasswordClose,
      Forgotemail,
      Forgotpassword,
      OTPModalOpen,
      OTPModalClose,
      VerifyOTP,
      OTP,
      focusNext,
      timer,
      minutes,
      seconds,
      resendOTP,
    };
  },
};
</script>

<style scoped>
.otp-input-group {
  display: flex;
  justify-content: space-between;
}
.otp-input {
  width: 50px;
  text-align: center;
  margin: 0 5px;
}

.FrontPage {
  width: 100%;
  border-radius: 10px;
}

.LoginControl {
  border-top: none;
  border-right: none;
  border-left: none;
}

.form-control::placeholder {
  color: black;
}

.login-box {
  flex: 2;
  padding: 40px;
}

.logo {
  display: block;
  width: 100%;
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-form .d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-form .btn-login {
  width: 100%;
  background-color: #325f62;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.forgot-password-link {
  color: #007bff;
  cursor: pointer;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.forgot-form .form-group {
  margin-bottom: 15px;
}

.forgot-form .d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot-form .btn-forgot {
  background-color: #325f62;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
</style>
