<template>
  <div class="container-fluid">
    <div class="row" style="padding-left: 4cap">
      <div class="col-2">
        <label class="dateLabelFrom">From:</label>
        <input
          type="date"
          class="form-control"
          placeholder="Select Date"
          v-model="Fromdate"
          @change="onHandleChange()"
        />
      </div>
      <div class="col-2">
        <label class="dateLabelTo">To:</label>
        <input
          type="date"
          class="form-control"
          placeholder="Select Date"
          v-model="Todate"
          @change="onHandleChange()"
        />
      </div>
      <div class="col-2">
        <div class="form-group">
          <select
            id="provincesDropdown"
            class="form-control"
            v-model="selectedProvinces"
            @change="onProvinceChange"
            @focus="fetchProvinces"
          >
            <option value="">Select a Province</option>
            <option
              v-for="(province, index) in provinces"
              :key="index"
              :value="province.name"
            >
              {{ province.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <select
            id="townDropdown"
            class="form-control"
            v-model="selectedTown"
            @change="onTownChange"
            v-if="towns.length > 0"
          >
            <option value="">Select a Town</option>
            <option
              v-for="(town, index) in towns"
              :key="index"
              :value="town.name"
            >
              {{ town.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <select
            id="barangayDropdown"
            class="form-control"
            v-model="selectedBarangay"
            @change="onHandleChange()"
            v-if="barangays.length > 0"
          >
            <option value="">Select a Barangay</option>
            <option
              v-for="(barangay, index) in barangays"
              :key="index"
              :value="barangay.name"
            >
              {{ barangay.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group d-flex align-items-center">
          <select
            id="DashboardDropdown"
            class="form-control"
            v-model="selectedDashboard"
          >
            <option value="Tree Dashboard">Tree Dashboard</option>
            <option value="Tree Cutting Dashboard">
              Tree Cutting Dashboard
            </option>
          </select>

          <span
            @click="generateExcel()"
            class="material-icons button-icon"
            style="color: grey"
          >
            table_view
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-9 position-relative">
        <div class="map-container">
          <GoogleMap
            :api-key="apiKey"
            class="full-viewport-map"
            :center="MapCenter"
            :zoom="zoom"
            mapTypeId="satellite"
          >
            <Marker
              v-for="tree in combinedTrees"
              :key="tree.id"
              :options="{
                position: {
                  lat: parseFloat(tree.Lat),
                  lng: parseFloat(tree.Lng),
                },
                icon: getMarkerIcon(tree),
              }"
              @click="openInfoWindow(tree.id)"
            >
              <InfoWindow
                v-if="infoWindowOpen[tree.id]"
                :position="{
                  lat: parseFloat(tree.Lat),
                  lng: parseFloat(tree.Lng),
                }"
                @closeclick="closeInfoWindow(tree.id)"
              >
                <div class="TreeInfo">
                  <div class="row">
                    <div class="col-6 TreeLatLng">
                      <center>
                        <img
                          :src="`${TREE_URL}${tree.tree_pic}`"
                          style="width: 100%; height: auto; object-fit: contain"
                        />
                      </center>

                      <div class="LatLng-Container mt-3">
                        <h6 class="Lat">Lat: {{ tree.Lat }}</h6>
                        <h6 class="Lng">Lng: {{ tree.Lng }}</h6>
                      </div>
                    </div>

                    <div
                      class="col-6 d-flex justify-content-center align-items-center"
                    >
                      <img
                        :src="`${QR_URL}${tree.qr_pic}`"
                        style="width: 100%; height: auto; object-fit: contain"
                      />
                    </div>
                  </div>

                  <center>
                    <h3 class="mt-2 mb-2" style="font-weight: bold">
                      {{ tree.nameTag }}
                    </h3>
                  </center>

                  <div class="row">
                    <div class="col-6">
                      <h5>Common Name: {{ tree.com_Name }}</h5>
                      <h5>Scientific Name: {{ tree.sci_Name }}</h5>
                      <h5>Family Name: {{ tree.fam_Name }}</h5>
                      <h5>Origin: {{ tree.origin }}</h5>
                      <h5>Conservation Status: {{ tree.conserve_status }}</h5>
                      <h5>Uses: {{ tree.uses }}</h5>
                    </div>

                    <div class="col-6">
                      <h5>Tagging Status: {{ tree.tagging_stat }}</h5>
                      <h5>Protection: {{ tree.protection }}</h5>
                      <h5>Species: {{ tree.species }}</h5>
                      <h5>
                        Tagger: {{ tree.tagger.Fname }} {{ tree.tagger.Lname }}
                      </h5>
                      <h5>
                        Location: {{ tree.barangay }}, {{ tree.town }},
                        {{ tree.province }}
                      </h5>
                      <h5>Date Tag: {{ tree.date }}</h5>
                    </div>
                  </div>
                </div>
              </InfoWindow>
            </Marker>
          </GoogleMap>
          <div class="Legend">
            <center>
              <label>
                <input
                  type="radio"
                  v-model="status"
                  value=""
                  class="d-none"
                  @change="Search"
                />
                <strong><span class="text-nowrap">LEGEND</span></strong>
              </label>
            </center>
            <div class="d-flex flex-column align-items-start">
              <label class="d-flex align-items-center">
                <input
                  type="radio"
                  v-model="status"
                  value="Standing"
                  class="d-none"
                  @change="Search"
                />
                <img src="GreenCircle.png" class="legend-img" />
                <span class="text-nowrap">Standing</span>
              </label>

              <label class="d-flex align-items-center">
                <input
                  type="radio"
                  v-model="status"
                  value="Snag"
                  class="d-none"
                  @change="Search"
                />
                <img src="YellowCircle.png" class="legend-img" />
                <span class="text-nowrap">Snag</span>
              </label>

              <label class="d-flex align-items-center">
                <input
                  type="radio"
                  v-model="status"
                  value="Cut"
                  class="d-none"
                  @change="Search"
                />
                <img src="RedCircle.png" class="legend-img" />
                <span class="text-nowrap">Cut</span>
              </label>

              <label class="d-flex align-items-center">
                <img src="PurpleCircle.png" class="legend-img" />
                <span class="text-nowrap">New Tree</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <ConservationStatusChart
          :threatened-count="dashboardData.totalThreatened"
          :endangered-count="dashboardData.totalEndangered"
          :unclassified-count="dashboardData.totalUnclassified"
        />
        <TreeStatusChart
          class="mt-2"
          :standing-count="dashboardData.totalStanding"
          :snag-count="dashboardData.totalSnag"
          :cut-count="dashboardData.totalCut"
        />
      </div>
    </div>

    <div v-if="selectedDashboard === 'Tree Dashboard'">
      <div class="row mt-2">
        <div class="col-3">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i
                      class="fas fa-tree primary font-large-2 float-left mb-2"
                    ></i>
                  </div>
                  <div class="media-body text-right">
                    <h3 class="card-text">{{ dashboardData.totalTree }}</h3>
                    <h5 class="card-title">Total Trees</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i
                      class="icon-check primary font-large-2 float-left mb-2"
                    ></i>
                  </div>
                  <div class="media-body text-right">
                    <h3 class="card-text">{{ dashboardData.totalUser }}</h3>
                    <h5 class="card-title">Tagger</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6"></div>
      </div>

      <div class="row">
        <div class="col-8">
          <div class="col-2 MonthlyYearly">
            <div class="form-group">
              <select
                id="MonthlyYearDropdown"
                class="form-control MonthlyYearDropdown"
                v-model="MonthlyYearly"
              >
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
          </div>
          <div v-if="MonthlyYearly === 'Monthly'">
            <MonthlyTaggedChart :monthlyCounts="dashboardData.monthlyCounts" />
          </div>
          <div v-else>
            <YearlyCountsChart :yearlyCounts="dashboardData.yearlyCounts" />
          </div>
        </div>
        <div class="col-4">
          <ScanCountChart :scan-counts="dashboardData.ScanCount" />
        </div>
      </div>

      <div class="row mt-2 pb-2">
        <div class="col-6">
          <EveryTreeCount :comNameCounts="dashboardData.comNameCounts" />
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <OriginStatusChart
                :native-count="dashboardData.totalNative"
                :exotic-count="dashboardData.totalExotic"
              />
            </div>
            <div class="col-6">
              <TaggingStatusChart
                :tagged-count="dashboardData.totalTagged"
                :untagged-count="dashboardData.totalUntagged"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <ProtectionStatusChart
                :normal-count="dashboardData.totalNormal"
                :significant-count="dashboardData.totalSignificant"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="selectedDashboard === 'Tree Cutting Dashboard'">
      <div class="row mt-2">
        <div class="col-3">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i
                      class="fas bi-tree primary font-large-2 float-left mb-2"
                    ></i>
                  </div>
                  <div class="media-body text-right">
                    <h3 class="card-text">
                      {{ dashboardData.totalCuttedTreesNational }}
                    </h3>
                    <h5 class="card-title">Total Cut Trees (Gov.)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="media d-flex">
                  <div class="align-self-center">
                    <i
                      class="fas bi-tree primary font-large-2 float-left mb-2"
                    ></i>
                  </div>
                  <div class="media-body text-right">
                    <h3 class="card-text">
                      {{ dashboardData.totalCuttedTreesPublic }}
                    </h3>
                    <h5 class="card-title">Total Cut Trees(Private)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  colspan="5"
                  class="text-center"
                  style="background-color: rgba(50, 95, 98, 0.3)"
                >
                  Government Agency Applicant for Tree Cutting
                </th>
              </tr>
              <tr colspan="2" class="text-center">
                <th>Pending</th>
                <th>Scheduled</th>
                <th>Incomplete</th>
                <th>Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr colspan="2" class="text-center">
                <th>{{ dashboardData.totalPendingNational }}</th>
                <th>{{ dashboardData.totalScheduledNational }}</th>
                <th>{{ dashboardData.totalIncompleteNational }}</th>
                <th>{{ dashboardData.totalCompletedNational }}</th>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-6">
          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  colspan="5"
                  class="text-center"
                  style="background-color: rgba(255, 99, 132, 0.3)"
                >
                  Private Individual Applicant for Tree Cutting
                </th>
              </tr>
              <tr colspan="2" class="text-center">
                <th>Pending</th>
                <th>Scheduled</th>
                <th>Incomplete</th>
                <th>Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr colspan="2" class="text-center">
                <th>{{ dashboardData.totalPendingPublic }}</th>
                <th>{{ dashboardData.totalScheduledPublic }}</th>
                <th>{{ dashboardData.totalIncompletePublic }}</th>
                <th>{{ dashboardData.totalCompletedPublic }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row mt-2 pb-4">
        <div class="col-6">
          <CuttedTreesMonthlyChart
            :monthlynationalcuttedCounts="dashboardData.monthlyNationalCounts"
            :monthlypubliccuttedCounts="dashboardData.monthlyPublicCounts"
          />
        </div>
        <div class="col-6">
          <CuttedTreesYearlyChart
            :yearlynationalcuttedCounts="dashboardData.yearlyNationalCounts"
            :yearlypubliccuttedCounts="dashboardData.yearlyPublicCounts"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import axios from "axios";
import { useRoute } from "vue-router";
import { API_URL, TREE_URL, QR_URL } from "../config";
import * as XLSX from "xlsx";
import TreeStatusChart from "../components/TreeStatusChart.vue";
import TaggingStatusChart from "../components/TaggingStatusChart.vue";
import ProtectionStatusChart from "../components/ProtectionStatusChart.vue";
import OriginStatusChart from "../components/OriginStatusChart.vue";
import ConservationStatusChart from "../components/ConservationStatusChart.vue";
import MonthlyTaggedChart from "../components/MonthlyTaggedChart.vue";
import ScanCountChart from "../components/ScanCountChart.vue";
import YearlyCountsChart from "../components/YearlyCountsChart.vue";
import CuttedTreesMonthlyChart from "../components/CuttedTreesMonthlyChart.vue";
import CuttedTreesYearlyChart from "../components/CuttedTreesYearlyChart.vue";
import EveryTreeCount from "../components/EveryTreeCount.vue";

const apiKey = "AIzaSyBrqSrJKShAkR7iDx8NzxBE99_27mGbcBc";
const MapCenter = ref({ lat: 16.720981, lng: 121.689062 });
const zoom = ref(14);
const allTrees = ref([]);
const combinedTrees = ref([]);
const infoWindowOpen = ref({});
const greenCircleIcon = ref(null);
const purpleCircleIcon = ref(null);
const redCircleIcon = ref(null);
const yellowCircleIcon = ref(null);
const status = ref("");
const route = useRoute();
const selectedProvinces = ref("");
const selectedTown = ref("");
const selectedBarangay = ref("");
const provinces = ref([]);
const towns = ref([]);
const barangays = ref([]);
const Todate = ref("");
const Fromdate = ref("");
const selectedDashboard = ref("Tree Dashboard");
const MonthlyYearly = ref("Monthly");
const dashboardData = ref({
  totalStanding: 0,
  totalSnag: 0,
  totalCut: 0,
  totalTagged: 0,
  totalUntagged: 0,
  totalNormal: 0,
  totalSignificant: 0,
  totalNative: 0,
  totalExotic: 0,
  totalThreatened: 0,
  totalEndangered: 0,
  totalUnclassified: 0,
  monthlyCounts: {},
  ScanCount: [],
  yearlyCounts: {},
  totalTree: 0,
  totalUser: 0,
  monthlyNationalCounts: {},
  monthlyPublicCounts: {},
  yearlyNationalCounts: {},
  yearlyPublicCounts: {},
  totalCuttedTreesNational: 0,
  totalCuttedTreesPublic: 0,
  totalPendingPublic: 0,
  totalScheduledPublic: 0,
  totalIncompletePublic: 0,
  totalCompletedPublic: 0,
  totalPendingNational: 0,
  totalScheduledNational: 0,
  totalIncompleteNational: 0,
  totalCompletedNational: 0,
  comNameCounts: {},
});
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

onMounted(async () => {
  greenCircleIcon.value = {
    url: require("@/assets/GreenCircle.png"),
    scaledSize: { width: 16, height: 16 },
  };

  purpleCircleIcon.value = {
    url: require("@/assets/PurpleCircle.png"),
    scaledSize: { width: 16, height: 16 },
  };

  redCircleIcon.value = {
    url: require("@/assets/RedCircle.png"),
    scaledSize: { width: 16, height: 16 },
  };

  yellowCircleIcon.value = {
    url: require("@/assets/YellowCircle.png"),
    scaledSize: { width: 16, height: 16 },
  };

  try {
    const response = await axios.get(`${API_URL}/trees`);
    allTrees.value = response.data.trees;
    allTrees.value.forEach((tree) => {
      tree.Lat = parseFloat(tree.Lat);
      tree.Lng = parseFloat(tree.Lng);
    });
    combinedTrees.value = [...allTrees.value];

    const lat = parseFloat(route.query.lat);
    const lng = parseFloat(route.query.lng);
    if (!isNaN(lat) && !isNaN(lng)) {
      MapCenter.value = { lat, lng };
      zoom.value = 20;
      openInfoWindow(tree.id);
    }
  } catch (error) {}

  fetchDashboard();
  fetchProvinces();
});

const fetchDashboard = async () => {
  try {
    const responseUser = await axios.get(`${API_URL}/dashboardUser`);
    const responseTree = await axios.get(`${API_URL}/dashboardTree`, {
      params: {
        province: selectedProvinces.value,
        town: selectedTown.value,
        barangay: selectedBarangay.value,
        Fromdate: Fromdate.value,
        Todate: Todate.value,
      },
    });
    const responseNational = await axios.get(`${API_URL}/dashboardNational`, {
      params: {
        province: selectedProvinces.value,
        town: selectedTown.value,
        barangay: selectedBarangay.value,
        Fromdate: Fromdate.value,
        Todate: Todate.value,
      },
    });
    const responsePublic = await axios.get(`${API_URL}/dashboardPublic`, {
      params: {
        province: selectedProvinces.value,
        town: selectedTown.value,
        barangay: selectedBarangay.value,
        Fromdate: Fromdate.value,
        Todate: Todate.value,
      },
    });
    dashboardData.value = {
      ...responseUser.data,
      ...responseTree.data,
      ...responseNational.data,
      ...responsePublic.data,
    };
  } catch (error) {}
};

const Search = async () => {
  try {
    const response = await axios.get(`${API_URL}/searchquerybarangay`, {
      params: {
        status: status.value,
        province: selectedProvinces.value,
        town: selectedTown.value,
        barangay: selectedBarangay.value,
        Fromdate: Fromdate.value,
        Todate: Todate.value,
      },
    });

    combinedTrees.value = response.data.trees;
  } catch (error) {}
};

function moveMap() {
  const selectedProvince = selectedProvinces.value;
  const selectedTownValue = selectedTown.value;
  const selectedBarangayValue = selectedBarangay.value;

  const searchParts = [];

  if (selectedProvince) {
    searchParts.push(selectedProvince);
  }
  if (selectedTownValue) {
    searchParts.push(selectedTownValue);
  }
  if (selectedBarangayValue) {
    searchParts.push(selectedBarangayValue);
  }

  const searchString = searchParts.length > 0 ? searchParts.join(", ") : "";

  if (!searchString) {
    return;
  }

  const autocompleteService = new google.maps.places.AutocompleteService();

  autocompleteService.getPlacePredictions(
    { input: searchString, types: ["geocode"] },
    (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const placeId = predictions[0].place_id;
        const placesService = new google.maps.places.PlacesService(
          document.createElement("div")
        );
        placesService.getDetails(
          { placeId: placeId },
          (placeDetails, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              placeDetails.geometry
            ) {
              const lat = placeDetails.geometry.location.lat();
              const lng = placeDetails.geometry.location.lng();
              MapCenter.value = { lat: lat, lng: lng };
            } else {
            }
          }
        );
      } else {
      }
    }
  );
}

const fetchProvinces = async () => {
  try {
    const response = await axios.get(`${API_URL}/provinces`);
    provinces.value = response.data.map((province) => ({
      name: province.name,
      code: province.code,
    }));
  } catch (error) {}
};

const fetchTown = async (provinceCode) => {
  try {
    const response = await axios.get(
      `${API_URL}/provinces/${provinceCode}/municipalities`
    );
    towns.value = response.data.map((town) => ({
      name: town.name,
      code: town.code,
    }));
  } catch (error) {}
};

const fetchBarangays = async (townCode) => {
  try {
    const response = await axios.get(
      `${API_URL}/municipalities/${townCode}/barangays`
    );
    barangays.value = response.data.map((barangay) => ({
      name: barangay.name,
    }));
  } catch (error) {}
};

const onProvinceChange = (event) => {
  const selectedProvinceName = event.target.value;
  const selectedProvince = provinces.value.find(
    (province) => province.name === selectedProvinceName
  );
  if (selectedProvince) {
    fetchTown(selectedProvince.code);
  }
  Search();
  moveMap();
  fetchDashboard();
};

const onTownChange = (event) => {
  const selectedTownName = event.target.value;
  const selectedTownData = towns.value.find(
    (town) => town.name === selectedTownName
  );
  if (selectedTownData) {
    fetchBarangays(selectedTownData.code);
  }
  Search();
  moveMap();
  fetchDashboard();
};

const onHandleChange = () => {
  Search();
  moveMap();
  fetchDashboard();
};

const getMonthName = (dateString) => {
  const [year, month] = dateString.split("-");
  const monthIndex = parseInt(month, 10) - 1;
  return monthNames[monthIndex] + " " + year;
};

const generateExcel = () => {
  const Excel = dashboardData.value;

  const worksheetData = [
    [
      (Fromdate.value === "" && Todate.value === "" ? "No Selected Date" : "") +
        (Fromdate.value === "" ? "" : "Date From: ") +
        Fromdate.value +
        (Todate.value === "" ? "" : "To: ") +
        Todate.value,
    ],
    [
      (selectedProvinces.value === "" ? "No Selected Location" : "Location: ") +
        selectedProvinces.value +
        (selectedTown.value === "" ? " " : ",") +
        selectedTown.value +
        (selectedBarangay.value === "" ? " " : ",") +
        selectedBarangay.value,
    ],
    ["", ""],

    ["Trees"],
    ["Total", Excel.totalTree],
    ["", ""],

    ["Conservation Status", "Total", ""],
    ["Endangered", Excel.totalEndangered, ""],
    ["Threatened", Excel.totalThreatened, ""],
    ["Unclassified", Excel.totalUnclassified, ""],
    ["", ""],

    ["Tree Status", "Total", ""],
    ["Standing", Excel.totalStanding, ""],
    ["Snag", Excel.totalSnag, ""],
    ["Cut", Excel.totalCut, ""],
    ["", ""],

    ["Origin", "Total", ""],
    ["Native", Excel.totalNative, ""],
    ["Exotic", Excel.totalExotic, ""],
    ["", ""],

    ["Tagging Status", "Total", ""],
    ["Tagged", Excel.totalTagged, ""],
    ["Untagged", Excel.totalUntagged, ""],
    ["", ""],

    ["Important", "Total", ""],
    ["Normal", Excel.totalNormal, ""],
    ["Significant", Excel.totalSignificant, ""],
    ["", ""],

    ["Scan Count", "", ""],
    ["Common Name", "Count", ""],
    ...(Excel.ScanCount && Excel.ScanCount.length > 0
      ? Excel.ScanCount.map((item) => [item.nameTag, item.scanCount])
      : [["No data", ""]]),
    ["", ""],

    ["Tree Species", "", ""],
    ["Common Name", "Total", ""],
    ...(Excel.comNameCounts && Excel.comNameCounts.length > 0
      ? Excel.comNameCounts.map((item) => [item.com_Name, item.count])
      : [["No data", ""]]),
    ["", ""],

    ["Monthly Tagged Trees", "Total", ""],
    ...Object.entries(Excel.monthlyCounts).map(([key, value]) => [
      getMonthName(key),
      value,
    ]),
    ["", ""],

    ["Yearly Tagged Trees", "Total", ""],
    ...Object.entries(Excel.yearlyCounts).map(([key, value]) => [key, value]),
    ["", ""],

    ["Monthly Cut Trees", "Government", "Public"],
    ...Object.entries(Excel.monthlyNationalCounts).map(
      ([key, nationalValue]) => [
        getMonthName(key),
        nationalValue,
        Excel.monthlyPublicCounts[key] || 0,
      ]
    ),
    ["", ""],

    ["Yearly Cut Trees", "Government", "Public"],
    ...Object.entries(Excel.yearlyNationalCounts).map(
      ([key, nationalValue]) => [
        key,
        nationalValue,
        Excel.yearlyPublicCounts[key] || 0,
      ]
    ),
    ["", ""],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, "Report.xlsx");
};

const openInfoWindow = (id) => {
  infoWindowOpen.value = { ...infoWindowOpen.value, [id]: true };
};

const closeInfoWindow = (id) => {
  infoWindowOpen.value = { ...infoWindowOpen.value, [id]: false };
};

const getMarkerIcon = (tree) => {
  if (tree.Tree_status === "Standing" && tree.isPresent === "Visible") {
    return greenCircleIcon.value;
  } else if (tree.Tree_status === "Snag" && tree.isPresent === "Visible") {
    return yellowCircleIcon.value;
  } else if (tree.Tree_status === "Cut" && tree.isPresent === "Visible") {
    return redCircleIcon.value;
  } else {
    return purpleCircleIcon.value;
  }
};
</script>

<style scoped>
.button-icon {
  font-size: 35px;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.card {
  transition: transform 0.2s;
  background-color: #f8f9fa;
  border-radius: 12px;
  height: 7rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: scale(1.05);
}

.MonthlyYearly {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  margin-top: 14px;
}

.dateLabelFrom {
  position: absolute;
  margin-left: -3rem;
  padding-top: 0.5rem;
}

.dateLabelTo {
  position: absolute;
  margin-left: -1.5rem;
  padding-top: 0.5rem;
}

.TreeInfo {
  width: 30rem;
}

.LatLng-Container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(240, 248, 255, 0.705);
  padding: 10px;
  text-align: center;
}

.Lat,
.Lng {
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: visible;
}

.map-container {
  position: relative;
  height: 550px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.full-viewport-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.input-group.Search {
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .search-bar-container {
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .search-bar-container {
    padding: 5px;
  }
}

.Legend {
  position: absolute;
  bottom: 0.4%;
  background-color: rgb(233, 225, 225);
  height: auto;
  width: 8rem;
  overflow-y: auto;
  margin-left: 0.2%;
  padding-top: 0.5rem;
  border-radius: 0.5rem;
  z-index: 1000;
}

.legend-img {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
}

.text-nowrap {
  margin-bottom: 0.2px;
}
</style>
