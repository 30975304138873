<template>
  <div class="container-fluid top-navbar">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Logos -->
          <div class="d-flex align-items-center">
            <img
              :src="require('@/assets/DENRLogoWhite.png')"
              alt="Logo"
              class="DENRlogo"
            />
            <img
              :src="require('@/assets/logofinal.png')"
              alt="Logo"
              class="logo ml-1"
            />
          </div>

          <!-- User Info, Notifications, and Logout -->
          <div class="d-flex align-items-center">
            <!-- User Info -->
            <div class="user-info d-flex align-items-center mr-4">
              <img
                :src="`${API_URL}/Profile-image/${user.photo}`"
                class="rounded-circle profile"
                alt="Profile"
              />
              <span class="ml-2">
                Admin: <a>{{ user.Fname }} {{ user.Lname }}</a>
              </span>
            </div>

            <!-- Notifications -->
            <div class="notifLogout d-flex align-items-center mr-4">
              <div class="popover-wrapper">
                <span class="material-icons" @click="notificationTrigger">
                  notifications
                </span>

                <!-- Unread notification count badge -->
                <span v-if="unreadNotificationCount" class="notification-badge">
                  {{ unreadNotificationCount }}
                </span>

                <div class="popover-wrapper">
                  <div v-if="isPopoverVisible" class="popover-content">
                    <h2 class="popover-title">Notifications</h2>
                    <div
                      v-for="(items, date) in groupedNotifications"
                      :key="date"
                    >
                      <div class="date-header">{{ formatDate(date) }}</div>
                      <div class="card" v-for="item in items" :key="item.id">
                        <div class="card-content">
                          <div class="notification-description">
                            {{ item.description }}
                          </div>
                          <div class="notification-time mt-2">
                            {{ item.time }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Logout -->
            <div class="notifLogout">
              <div class="button" @click="confirmLogout">
                <span class="material-icons">logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, computed, onUnmounted } from "vue";
import { API_URL } from "../config";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

const user = ref({ Fname: "", Lname: "", photo: "" });
const notification = ref([]);

const isPopoverVisible = ref(false);
const togglePopover = () => {
  isPopoverVisible.value = !isPopoverVisible.value;
};

const allNotifications = computed(() => {
  return Object.values(groupedNotifications.value).flat();
});

const notificationTrigger = () => {
  togglePopover();
  readNotification(allNotifications.value);
};

const fetchUserDetails = async () => {
  try {
    const id = localStorage.getItem("id");
    const response = await axios.get(`${API_URL}/user/${id}`);
    user.value = response.data.user;
  } catch (error) {}
};

const fetchNotification = async () => {
  try {
    const response = await axios.get(`${API_URL}/adminNotification`);

    notification.value = response.data.adminNotification;

    // notification.value.forEach((item) => {
    //   if (item.isRead === "false") {
    //     Swal.fire({
    //       toast: true,
    //       position: "top-end",
    //       iconHtml: "🔔",
    //       title: `${item.description}`,
    //       showConfirmButton: false,
    //       timer: 3000,
    //       timerProgressBar: true,
    //     });
    //   }
    // });
  } catch (error) {}
};

const readNotification = async (notifications) => {
  try {
    if (!notifications || notifications.length === 0) {
      throw new Error("No notifications to update.");
    }

    const unreadNotifications = notifications.filter(
      (notification) => notification.isRead === "false"
    );

    if (unreadNotifications.length === 0) {
      return;
    }

    for (const notification of unreadNotifications) {
      await axios.put(
        `${API_URL}/readnotification/${notification.id}`,
        notification.value
      );
    }
  } catch (error) {}
};

const unreadNotificationCount = computed(() => {
  return notification.value.filter((item) => item.isRead === "false").length;
});

const groupedNotifications = computed(() => {
  return notification.value.reduce((groups, item) => {
    const date = item.date;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
});

const formatDate = (date) => {
  const today = new Date().toISOString().split("T")[0];
  return date === today ? "Today" : new Date(date).toLocaleDateString();
};

const router = useRouter();

const confirmLogout = () => {
  Swal.fire({
    title: "Do you want to log out?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("Fname");
      localStorage.removeItem("Lname");
      localStorage.removeItem("account_type");

      router.push("/login").then(() => {
        window.location.reload();
      });
    }
  });
};

const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

onMounted(() => {
  if (isLoggedIn()) {
    fetchNotification();
    fetchUserDetails();

    const intervalId = setInterval(() => {
      fetchNotification();
    }, 60000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
  }
});
</script>

<style scoped>
.profile {
  width: 45px;
  height: 45px;
  margin-bottom: 5px;
}
.top-navbar {
  background-color: #325f62;
  height: 4.2rem;
}

.logo {
  margin-top: 0.7rem;
  height: 45px;
}

.DENRlogo {
  margin-top: 0.3rem;
  height: 55px;
}

.user-info {
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 0.7rem;
  text-align: end;
}

.notifLogout {
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 0.7rem;
}

.popover-wrapper {
  position: relative;
}

.popover-content {
  position: absolute;
  right: 0;
  background-color: #e9e9e9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1500;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  width: 27rem;
}

.popover-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: black;
}

.date-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 25rem;
}

.notification-description {
  font-size: 1rem;
  font-weight: bold;
  color: black;
}

.notification-time {
  font-size: 0.875rem;
  color: #666;
  text-align: right;
}

.notification-badge {
  position: absolute;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: bold;
}
</style>
