<template>
  <div class="chart-container">
    <PieChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default defineComponent({
  name: "ProtectionStatusChart",
  components: { PieChart: Pie },
  props: {
    normalCount: {
      type: Number,
      required: true,
    },
    significantCount: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const chartData = computed(() => ({
      labels: ["Normal", "Significant"],
      datasets: [
        {
          label: "Protection Status",
          data: [props.normalCount, props.significantCount],
          backgroundColor: ["rgba(76, 175, 80, 0.8)", "rgba(244, 67, 54, 0.8)"],
          borderColor: ["rgba(76, 175, 80, 1)", "rgba(244, 67, 54, 1)"],
          borderWidth: 2,
          hoverOffset: 8,
          borderRadius: 10,
        },
      ],
    }));

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: 16,
              family: "Roboto, sans-serif",
              weight: "600",
            },
            color: "#333333",
          },
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          titleFont: {
            size: 14,
            family: "Roboto, sans-serif",
            weight: "600",
          },
          bodyFont: {
            size: 12,
            family: "Roboto, sans-serif",
          },
          padding: 12,
          borderColor: "#666666",
          borderWidth: 1,
        },
        // title: {
        //   display: true,
        //   // text: "Protection Status",
        //   position: "top",
        //   padding: {
        //     top: 15,
        //     bottom: 15,
        //   },
        //   font: {
        //     size: 22,
        //     weight: "700",
        //     family: "Roboto, sans-serif",
        //   },
        //   color: "#222222",
        // },
      },
      elements: {
        arc: {
          borderWidth: 2,
        },
      },
      cutout: "60%",
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      layout: {
        padding: {
          top: 15,
          bottom: 15,
          left: 15,
          right: 15,
        },
      },
    };

    return {
      chartData,
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  height: 350px;
  margin: auto;
  position: relative;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 20px;
}
</style>
