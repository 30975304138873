<template>
  <div class="chart-container">
    <LineChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default defineComponent({
  name: "CuttedTreesYearlyChart",
  components: {
    LineChart: Line,
  },
  props: {
    yearlynationalcuttedCounts: {
      type: Object,
      default: () => ({}),
    },
    yearlypubliccuttedCounts: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const chartData = computed(() => {
      // Extract years and counts directly
      const labels = Array.from(
        new Set([
          ...Object.keys(props.yearlynationalcuttedCounts),
          ...Object.keys(props.yearlypubliccuttedCounts),
        ])
      ).sort();

      const nationalData = labels.map(
        (year) => props.yearlynationalcuttedCounts[year] || 0
      );
      const publicData = labels.map(
        (year) => props.yearlypubliccuttedCounts[year] || 0
      );

      return {
        labels,
        datasets: [
          {
            label: "Government Agency",
            data: nationalData,
            borderColor: "rgba(50, 95, 98, 1)",
            backgroundColor: "rgba(50, 95, 98, 0.2)",
            borderWidth: 2,
            pointBackgroundColor: "rgba(50, 95, 98, 1)",
            pointBorderColor: "#ffffff",
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
          {
            label: "Private Individual",
            data: publicData,
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderWidth: 2,
            pointBackgroundColor: "rgba(255, 99, 132, 1)",
            pointBorderColor: "#ffffff",
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
        ],
      };
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 14,
              family: "Roboto, sans-serif",
            },
          },
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          titleFont: {
            size: 14,
            family: "Roboto, sans-serif",
            weight: "600",
          },
          bodyFont: {
            size: 12,
            family: "Roboto, sans-serif",
          },
          padding: 12,
          borderColor: "#666666",
          borderWidth: 1,
        },
        title: {
          display: true,
          text: "Cut Trees Yearly",
          position: "top",
          padding: {
            top: 15,
            bottom: 15,
          },
          font: {
            size: 22,
            weight: "700",
            family: "Roboto, sans-serif",
          },
          color: "#222222",
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Year",
            color: "#000000",
            font: {
              size: 14,
              family: "Roboto, sans-serif",
              weight: "600",
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: "Number of Trees",
            color: "#000000",
            font: {
              size: 14,
              family: "Roboto, sans-serif",
              weight: "600",
            },
          },
          grid: {
            color: "#e0e0e0",
            borderColor: "#e0e0e0",
          },
          beginAtZero: true,
        },
      },
      animation: {
        duration: 1000,
        easing: "easeInOutQuad",
      },
    };

    return {
      chartData,
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  height: 800px;
  position: relative;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 20px;
}

.chart-container canvas {
  height: 100%;
}
</style>
