<template>
  <div class="container-fluid footer">
    <div class="row">
      <div class="col-6 text-left">
        <div class="row reserve">
          <img
            :src="require('@/assets/FooterLogo2.png')"
            alt="Logo"
            class="denrlogo"
          />
          <p>
            &copy; {{ new Date().getFullYear() }} EMAPuno All Rights Reserved.
          </p>
        </div>
      </div>
      <div class="col-6 text-right">
        <ul class="developer">
          <li>Developed by:</li>
          <li>Gorospe, D.D</li>
          <li>Concepcion, M.H</li>
          <li>Gamet, R.A</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
.denrlogo {
  margin-left: 1rem;
  height: 45px;
  margin-top: -0.1rem;
}

.footer {
  background-color: #d9d9d9;
  overflow: hidden;
  height: 2.5rem;
}

.footer p {
  padding-top: 0.7rem;
  color: #6c757d;
}

.developer {
  list-style: none;
  padding-top: 0.5rem;
}

.developer li {
  display: inline;
  margin-right: 15px;
}

.developer a {
  color: #007bff;
  text-decoration: none;
}
</style>
