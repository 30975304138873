<template>
  <aside :class="`${is_expanded ? 'is-expanded' : ''}`">
    <div class="menu-toggle-wrap">
      <button class="menu-toggle" @click="ToggleMenu">
        <span class="material-icons">{{
          is_expanded ? "menu" : "keyboard_double_arrow_right"
        }}</span>
      </button>
    </div>

    <h3>Main</h3>
    <div class="menu">
      <router-link to="/home" class="button">
        <span class="material-icons">home</span>
        <span class="text">Home</span>
      </router-link>
      <router-link to="/manage" class="button">
        <span class="material-icons">group</span>
        <span class="text">Manage User</span>
      </router-link>
      <router-link to="/report" class="button">
        <span class="material-icons">description</span>
        <span class="text">Reports</span>
      </router-link>
      <div class="dropdown">
        <div class="button" @click="toggleDropdownTree">
          <span class="material-icons">menu</span>
          <span class="text">Tree Cutting Appointment</span>
          <span class="material-icons arrow">{{
            showDropdownTree ? "arrow_drop_up" : "arrow_drop_down"
          }}</span>
        </div>
        <div class="dropdown-content" v-if="showDropdownTree">
          <router-link
            to="/treecuttingappointment/pendingGovernment"
            class="button"
          >
            <span class="material-icons">account_balance</span>
            <span class="text">Govt. Agency Applicant</span>
          </router-link>
          <router-link
            to="/treecuttingappointment/pendingPublic"
            class="button"
          >
            <span class="material-icons">person</span>
            <span class="text">Private Applicant</span>
          </router-link>
        </div>
      </div>
    </div>

    <h3 class="up">Settings</h3>
    <div class="menu">
      <div class="dropdown">
        <div class="button" @click="toggleDropdownLogout">
          <span class="material-icons">settings</span>
          <span class="text">Settings</span>
          <span class="material-icons arrow">{{
            showDropdownLogout ? "arrow_drop_up" : "arrow_drop_down"
          }}</span>
        </div>
        <div class="dropdown-content" v-if="showDropdownLogout">
          <div class="button" @click="confirmLogout">
            <span class="material-icons">logout</span>
            <span class="text">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script setup>
import { ref, watch } from "vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

const is_expanded = ref(localStorage.getItem("is_expanded") === "true");

const ToggleMenu = () => {
  is_expanded.value = !is_expanded.value;
  localStorage.setItem("is_expanded", is_expanded.value);
  document.documentElement.style.setProperty(
    "--map-width",
    is_expanded.value ? "calc(100% - 250px)" : "calc(100% - 80px)"
  );
};

const showDropdownTree = ref(false);
const toggleDropdownTree = () => {
  showDropdownTree.value = !showDropdownTree.value;
};

const showDropdownLogout = ref(false);
const toggleDropdownLogout = () => {
  showDropdownLogout.value = !showDropdownLogout.value;
};

const router = useRouter();

const confirmLogout = () => {
  Swal.fire({
    title: "Do you want to log out?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("Fname");
      localStorage.removeItem("Lname");
      localStorage.removeItem("account_type");

      router.push("/login").then(() => {
        window.location.reload();
      });
    }
  });
};

watch(is_expanded, (newValue) => {
  document.documentElement.style.setProperty(
    "--map-width",
    newValue ? "calc(100% - 250px)" : "calc(100% - 80px)"
  );
});
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  color: var(--light);
  width: calc(2rem + 32px);
  overflow: hidden;
  max-height: 100vh;
  padding: 1rem;
  transition: 0.2s ease-in-out;
  border-right: none;
  margin-top: 1px;

  &.is-expanded {
    width: var(--sidebar-width);
    border-right: 1px solid grey;
  }

  .up {
    margin-top: 100px;
  }

  .menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    position: relative;
    top: 0;
    transition: 0.2s ease-in-out;

    .menu-toggle {
      transition: 0.2s ease-in-out;
      margin-top: 10px;
      .material-icons {
        font-size: 2rem;
        color: var(--dark);
        transition: 0.2s ease-out;
      }

      &:hover {
        .material-icons {
          color: var(--primary);
          transform: translateX(0.5rem);
        }
      }
    }
  }

  h3,
  .button .text {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  h3 {
    color: var(--grey);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .menu {
    margin: 0 -1rem;

    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: 0.2s ease-in-out;
      padding: 0.5rem 1rem;

      .material-icons {
        font-size: 1.5rem;
        color: var(--dark);
        transition: 0.2s ease-in-out;
      }
      .text {
        color: var(--dark-alt);
        transition: 0.2s ease-in-out;
      }

      &:hover {
        background-color: var(--light);
        .material-icons,
        .text {
          color: #325f62;
        }
      }

      &.router-link-exact-active {
        background-color: var(--light);
        border-right: 5px solid #325f62;
        .material-icons,
        .text {
          color: #325f62;
        }
      }
    }
  }

  .footer {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    p {
      font-size: 0.875rem;
      color: var(--grey);
    }
  }

  &.is-expanded {
    width: var(--sidebar-width);

    .menu-toggle-wrap {
      top: -3rem;
      .menu-toggle {
        transform: rotate(-180deg);
      }
    }

    h3,
    .button .text {
      opacity: 1;
    }

    .button {
      .material-icons {
        margin-right: 1rem;
      }
    }

    .footer {
      opacity: 1;
    }
  }

  .popover-wrapper {
    position: relative;
  }

  .popover-trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
  }
}
</style>
