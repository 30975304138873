import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import HomePage from "@/views/HomePage.vue";
import Report from "@/views/Report.vue";
import Manage from "@/views/Manage.vue";
import PendingGovernment from "@/views/PendingGovernment.vue";
import ScheduledGovernment from "@/views/ScheduledGovernment.vue";
import IncompleteGovernment from "@/views/IncompleteGovernment.vue";
import PendingPublic from "@/views/PendingPublic.vue";
import ScheduledPublic from "@/views/ScheduledPublic.vue";
import IncompletePublic from "@/views/IncompletePublic.vue";
import TreeCuttingAppointment from "../views/TreeCuttingAppointment.vue";
import CompletedGovernment from "../views/CompletedGovernment.vue";
import CompletedPublic from "../views/CompletedPublic.vue";
import OverdueGovernment from "../views/OverdueGovernment.vue";
import OverduePublic from "../views/OverduePublic.vue";

const isAuthenticated = () => !!localStorage.getItem("token");

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next({ path: "/home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/21171109365127536",
    name: "Register",
    component: Register,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next({ path: "/home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: { onlyAdmin: true },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: { onlyAdmin: true },
  },
  {
    path: "/manage",
    name: "Manage",
    component: Manage,
    meta: { onlyAdmin: true },
  },
  {
    path: "/treecuttingappointment",
    component: TreeCuttingAppointment,
    redirect: "/treecuttingappointment/pendingGovernment",
    children: [
      {
        path: "pendingGovernment",
        component: PendingGovernment,
      },
      {
        path: "scheduledGovernment",
        component: ScheduledGovernment,
      },
      {
        path: "incompleteGovernment",
        component: IncompleteGovernment,
      },
      {
        path: "completedGovernment",
        component: CompletedGovernment,
      },
      {
        path: "overdueGovernment",
        component: OverdueGovernment,
      },
      {
        path: "pendingPublic",
        component: PendingPublic,
      },
      {
        path: "scheduledPublic",
        component: ScheduledPublic,
      },
      {
        path: "incompletePublic",
        component: IncompletePublic,
      },
      {
        path: "completedPublic",
        component: CompletedPublic,
      },
      {
        path: "overduePublic",
        component: OverduePublic,
      },
    ],
    meta: { onlyAdmin: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const account_type = localStorage.getItem("account_type");

  if (to.matched.some((record) => record.meta.onlyAdmin)) {
    if (account_type === "Admin") {
      next();
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.NonAdmin)) {
    if (account_type === "forester") {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
