<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-2 ml-3">
            <label class="dateLabelFrom">From:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Fromdate"
              @change="Search"
            />
          </div>
          <div class="col-2 ml-1">
            <label class="dateLabelTo">To:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Todate"
              @change="Search"
            />
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="provincesDropdown"
                class="form-control"
                v-model="selectedProvinces"
                @change="onProvinceChange"
                @focus="fetchProvinces"
              >
                <option value="">Select a Province</option>
                <option
                  v-for="(province, index) in provinces"
                  :key="index"
                  :value="province.name"
                >
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="townDropdown"
                class="form-control"
                v-model="selectedTown"
                @change="onTownChange"
                v-if="towns.length > 0"
              >
                <option value="">Select a Town</option>
                <option
                  v-for="(town, index) in towns"
                  :key="index"
                  :value="town.name"
                >
                  {{ town.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="barangayDropdown"
                class="form-control"
                v-model="selectedBarangay"
                @change="Search()"
                v-if="barangays.length > 0"
              >
                <option value="">Select a Barangay</option>
                <option
                  v-for="(barangay, index) in barangays"
                  :key="index"
                  :value="barangay.name"
                >
                  {{ barangay.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="search-bar-container">
          <div class="input-group Search">
            <input
              type="search"
              class="form-control rounded"
              id="search_input"
              @keydown.enter="Search"
              v-model="searchInput"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
            <button
              type="button"
              class="btn btn-outline-primary ml-1"
              @click="Search"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Govt. Agency Applicant</th>
              <th>Contact Number</th>
              <th>Agency</th>
              <th>Location</th>
              <th>No. of Trees</th>
              <th>Request Date</th>
              <th>Accomplish Requirement</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="paginatedAppointment.length === 0">
              <td colspan="11" class="text-center">No results found</td>
            </tr>
            <tr v-for="item in paginatedAppointment" :key="item.id">
              <td>{{ item.user.Fname }} {{ item.user.Lname }}</td>
              <td>{{ item.user.phone }}</td>
              <td>{{ item.app_Agency }}</td>
              <td>{{ item.barangay }}, {{ item.town }}, {{ item.province }}</td>
              <td>{{ item.tree_num }}</td>
              <td>{{ item.req_Date }}</td>
              <td>
                <a
                  href="#"
                  @click.prevent="openModal(item)"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  <span class="material-icons button-icon">checklist</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-controls">
        <button @click="previousPage" :disabled="currentPage === 1">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next
        </button>
      </div>
    </div>

    <!-- Main Modal for Accomplish Requirements -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModalLabel"
      style="overflow-y: scroll"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-if="scheduledNational">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">
              Accomplish Requirements
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Headers for columns -->
            <div class="row mb-3">
              <div class="col-4">
                <center><h5>REQUIREMENTS</h5></center>
              </div>
              <div class="col-2">
                <center><h5>SUBMITTED</h5></center>
              </div>
              <div class="col-3">
                <center><h5>COMPLIANT</h5></center>
              </div>
              <div class="col-3">
                <center><h5>REMARKS</h5></center>
              </div>
            </div>

            <!-- Row for each requirement -->
            <div
              v-for="(requirement, key) in requirements"
              :key="key"
              class="row mb-3"
            >
              <div class="col-4">
                <p>
                  <strong>{{ requirement.name }}</strong>
                </p>
              </div>
              <div class="col-2">
                <center>
                  <a
                    href="#"
                    @click.prevent="
                      showContent(requirement.key, requirement.type)
                    "
                    class="text-primary"
                    >View requirement</a
                  >
                </center>
              </div>
              <div class="col-3">
                <center>
                  <input
                    type="radio"
                    :id="requirement.key + '_yes'"
                    value="Yes"
                    v-model="scheduledNational[requirement.compliant]"
                  />
                  <label :for="requirement.key + '_yes'">Yes</label>
                  <input
                    type="radio"
                    :id="requirement.key + '_no'"
                    value="No"
                    v-model="scheduledNational[requirement.compliant]"
                  />
                  <label :for="requirement.key + '_no'">No</label>
                </center>
              </div>
              <div class="col-3">
                <center>
                  <input
                    type="text"
                    class="form-control"
                    v-model="scheduledNational[requirement.remarks]"
                    :disabled="
                      scheduledNational[requirement.compliant] !== 'No'
                    "
                  />
                </center>
              </div>
            </div>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="calendarModal1()"
            >
              Incomplete
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="calendarModal()"
              :disabled="!allCompliant"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Separate Modal for Image -->
    <div
      class="modal fade"
      id="contentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="contentModalLabel"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <template v-if="contentType === 'image'">
              <img
                :src="contentUrl"
                alt="Image Preview"
                style="max-width: 100%"
              />
            </template>
            <template v-if="contentType === 'pdf'">
              <div
                class="pdf-container"
                style="width: 100%; height: 100vh; overflow: auto"
              >
                <embed
                  :src="contentUrl"
                  type="application/pdf"
                  style="width: 100%; height: 100%"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Calendar Modal for schedule Date -->
    <div
      class="modal fade"
      id="calendarModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="calendarModalLabel"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Appointment Date</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="calendarModalClose"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input
              type="date"
              class="form-control"
              v-if="scheduledNational"
              v-model="scheduledNational.app_Date"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="Scheduled()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Calendar Modal for Due Date -->
    <div
      class="modal fade"
      id="calendarModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="calendarModalLabel"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Due Date</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="calendarModalClose1"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input
              type="date"
              class="form-control"
              v-if="scheduledNational"
              v-model="scheduledNational.due_Date"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmIncomplete(scheduledNational)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL, REQUIREMENT_URL } from "../config";

export default {
  setup() {
    const pendingNational = ref([]);
    const scheduledNational = ref(null);
    const contentType = ref(null);
    const contentUrl = ref(null);
    const showContentKey = ref(null);
    const Fromdate = ref("");
    const Todate = ref("");
    const Agency = ref("");
    const searchInput = ref("");
    const Admin_ID = localStorage.getItem("id");
    const itemsPerPage = 10;
    const currentPage = ref(1);
    const selectedProvinces = ref("");
    const selectedTown = ref("");
    const selectedBarangay = ref("");
    const provinces = ref([]);
    const towns = ref([]);
    const barangays = ref([]);

    const requirements = ref([
      {
        name: "Application letter (1 original)",
        key: "app_letter",
        compliant: "app_letter_compliant",
        remarks: "app_letter_remarks",
      },
      {
        name: "LGU Endorsement/Certification of No objection (1 original) *Brgy or Municipal LGU",
        key: "endorsement_Certification",
        compliant: "endorsement_Certification_compliant",
        remarks: "endorsement_Certification_remarks",
      },
      {
        name: "Approved Site Dev't Plan/Infrastructure Plan w/ tree charting (1 original)",
        key: "siteDevtPlan",
        compliant: "siteDevtPlan_compliant",
        remarks: "siteDevtPlan_remarks",
      },
      {
        name: "ECC/CNC, if applicable. The DENR RO/EMB shall determine if the tree cutting activities will require ECC/CNC based on the extent of tree cutting operations, location (e.g. Environmentally Critical Area), among others, if necessary (1 certified copy)",
        key: "ECC_CNC",
        compliant: "ECC_CNC_compliant",
        remarks: "ECC_CNC_remarks",
      },
      {
        name: "Free, Prior & Informed Consent (FPIC), if applicable",
        key: "FPIC",
        compliant: "FPIC_compliant",
        remarks: "FPIC_remarks",
      },
      {
        name: "Waiver/Consent of owner/s, if titled property (1 original)",
        key: "ownerconsent",
        compliant: "ownerconsent_compliant",
        remarks: "ownerconsent_remarks",
      },
      {
        name: "PAMB Clearance/Resolution, if w/in Protected Area (1 original)",
        key: "pambclearance",
        compliant: "pambclearance_compliant",
        remarks: "pambclearance_remarks",
      },
    ]);

    const fetchPendingNational = async () => {
      try {
        const response = await axios.get(`${API_URL}/pendingNational`);
        pendingNational.value = response.data.PendingNational;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong while fetching the data!",
        });
      }
    };

    const Search = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/SearchQueryPendingNational`,
          {
            params: {
              query: searchInput.value,
              province: selectedProvinces.value,
              town: selectedTown.value,
              barangay: selectedBarangay.value,
              Fromdate: Fromdate.value,
              Todate: Todate.value,
              agency: Agency.value,
            },
          }
        );
        pendingNational.value = response.data.SearchPendingNational;
      } catch (error) {}
    };

    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${API_URL}/provinces`);
        provinces.value = response.data.map((province) => ({
          name: province.name,
          code: province.code,
        }));
      } catch (error) {}
    };

    const fetchTown = async (provinceCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/provinces/${provinceCode}/municipalities`
        );
        towns.value = response.data.map((town) => ({
          name: town.name,
          code: town.code,
        }));
      } catch (error) {}
    };

    const fetchBarangays = async (townCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/municipalities/${townCode}/barangays`
        );
        barangays.value = response.data.map((barangay) => ({
          name: barangay.name,
        }));
      } catch (error) {}
    };

    const onProvinceChange = (event) => {
      const selectedProvinceName = event.target.value;
      const selectedProvince = provinces.value.find(
        (province) => province.name === selectedProvinceName
      );
      if (selectedProvince) {
        fetchTown(selectedProvince.code);
      }
      Search();
    };

    const onTownChange = (event) => {
      const selectedTownName = event.target.value;
      const selectedTownData = towns.value.find(
        (town) => town.name === selectedTownName
      );
      if (selectedTownData) {
        fetchBarangays(selectedTownData.code);
      }
      Search();
    };

    const getFileContentType = (fileName) => {
      if (!fileName) {
        return "unknown";
      }

      const extension = fileName.split(".").pop().toLowerCase();
      if (["png", "jpg", "jpeg", "gif"].includes(extension)) {
        return "image";
      } else if (["pdf"].includes(extension)) {
        return "pdf";
      } else {
        return "unknown";
      }
    };

    const showContent = (key) => {
      showContentKey.value = key;
      const fileName = scheduledNational.value[key];
      const type = getFileContentType(fileName);

      if (!fileName || type === "unknown") {
        return;
      }

      contentType.value = type;
      contentUrl.value = `${REQUIREMENT_URL}/Nationals/${fileName}`;

      $("#contentModal").modal("show");
    };

    const allCompliant = computed(() => {
      return requirements.value.every(
        (req) => scheduledNational.value[req.compliant] === "Yes"
      );
    });

    const Scheduled = async () => {
      try {
        const selectedDate = new Date(scheduledNational.value.app_Date);
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);
        selectedDate.setHours(0, 0, 0, 0);

        if (selectedDate === null) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please select a date before proceeding!",
          });
        } else if (selectedDate < currentDate) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The date cannot be in the past!",
          });
        } else {
          await axios.put(
            `${API_URL}/scheduledNational/${scheduledNational.value.id}`,
            {
              ...scheduledNational.value,
              admin_id: Admin_ID,
            }
          );
          calendarModalClose();
          closeModal();
          fetchPendingNational();
          successScheduled();
          closeContentModal();
          $(".modal-backdrop").remove();
        }
      } catch (error) {}
    };

    function successScheduled() {
      Swal.fire({
        title: "Tree cutting appointment Scheduled!",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }

    const Incomplete = async (item) => {
      try {
        const requestData = {
          app_letter_compliant: item.app_letter_compliant,
          app_letter_remarks: item.app_letter_remarks,
          endorsement_Certification_compliant:
            item.endorsement_Certification_compliant,
          endorsement_Certification_remarks:
            item.endorsement_Certification_remarks,
          siteDevtPlan_compliant: item.siteDevtPlan_compliant,
          siteDevtPlan_remarks: item.siteDevtPlan_remarks,
          ECC_CNC_compliant: item.ECC_CNC_compliant,
          ECC_CNC_remarks: item.ECC_CNC_remarks,
          FPIC_compliant: item.FPIC_compliant,
          FPIC_remarks: item.FPIC_remarks,
          ownerconsent_compliant: item.ownerconsent_compliant,
          ownerconsent_remarks: item.ownerconsent_remarks,
          pambclearance_compliant: item.pambclearance_compliant,
          pambclearance_remarks: item.pambclearance_remarks,
          due_Date: item.due_Date,
          admin_id: Admin_ID,
        };

        await axios.put(
          `${API_URL}/incompleteNational/${item.id}`,
          requestData
        );
        fetchPendingNational();
      } catch (error) {}
    };

    const confirmIncomplete = (item) => {
      const selectedDate = new Date(scheduledNational.value.due_Date);
      const currentDate = new Date();

      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);

      if (scheduledNational.value.due_Date === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select a date before proceeding!",
        });
      } else if (selectedDate < currentDate) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The date cannot be in the past!",
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, decline it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Incomplete(item);
            calendarModalClose1();
            closeModal();
            fetchPendingNational();
            closeContentModal();
            $(".modal-backdrop").remove();
            Swal.fire({
              title:
                "Tree cutting appointment Incomplete because it is incomplete!",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        });
      }
    };

    const openModal = (tree) => {
      scheduledNational.value = tree;
      $("#editModal").modal("show");
    };

    const closeModal = () => {
      scheduledNational.value = null;
      $("#editModal").modal("hide");
    };

    const closeContentModal = () => {
      $("#contentModal").modal("hide");
    };

    const calendarModal = () => {
      $("#editModal").modal("hide");
      $("#calendarModal").modal("show");
    };

    const calendarModalClose = () => {
      $("#calendarModal").modal("hide");
      $("#editModal").modal("show");
    };

    const calendarModal1 = () => {
      $("#editModal").modal("hide");
      $("#calendarModal1").modal("show");
    };

    const calendarModalClose1 = () => {
      $("#calendarModal1").modal("hide");
      $("#editModal").modal("show");
    };

    const paginatedAppointment = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return pendingNational.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(pendingNational.value.length / itemsPerPage);
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    onMounted(() => {
      fetchPendingNational();
      fetchProvinces();
    });

    return {
      pendingNational,
      scheduledNational,
      openModal,
      closeModal,
      requirements,
      calendarModal,
      calendarModalClose,
      calendarModal1,
      calendarModalClose1,
      Scheduled,
      successScheduled,
      allCompliant,
      confirmIncomplete,
      Fromdate,
      Todate,
      Agency,
      Search,
      searchInput,
      showContentKey,
      contentUrl,
      contentType,
      showContent,
      getFileContentType,
      paginatedAppointment,
      totalPages,
      previousPage,
      nextPage,
      currentPage,
      closeContentModal,
      fetchProvinces,
      provinces,
      selectedProvinces,
      fetchTown,
      towns,
      selectedTown,
      fetchBarangays,
      barangays,
      selectedBarangay,
      onProvinceChange,
      onTownChange,
    };
  },
};
</script>

<style scoped>
.dateLabelFrom {
  position: absolute;
  margin-left: -3rem;
  padding-top: 0.5rem;
}

.dateLabelTo {
  position: absolute;
  margin-left: -2rem;
  padding-top: 0.5rem;
}

.button-icon {
  font-size: 25px;
}

.table thead th {
  background-color: #325f62;
  padding: 0.5rem;
  color: white;
  border: none;
}

.table th,
.table td {
  text-align: center;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination-controls {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.pagination-controls button {
  margin: 0 0.5rem;
}

.pagination-controls span {
  margin: 0 0.5rem;
}

.page-item {
  display: inline-block;
  margin: 0 0.25rem;
}

.page-link {
  color: #007bff;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.page-link:hover {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-radius: 0.25rem;
}

.modal-header {
  background-color: #325f62;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.modal-title {
  font-size: 1.25rem;
  color: white;
  text-align: center;
}

.modal-header .close {
  color: white;
}

.modal-body p {
  font-size: 0.875rem;
  font-family: Arial, sans-serif;
  font-weight: normal;
}

.modal-body h5 {
  font-size: 1rem;
}

.modal-footer .btn {
  font-size: 0.875rem;
}

.btn-primary,
.btn-secondary {
  padding: 0.375rem 0.75rem;
}

.form-control-sm {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
