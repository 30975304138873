<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-2 ml-3">
            <label class="dateLabelFrom">From:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Fromdate"
              @change="Search"
            />
          </div>
          <div class="col-2 ml-1">
            <label class="dateLabelTo">To:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Todate"
              @change="Search"
            />
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="provincesDropdown"
                class="form-control"
                v-model="selectedProvinces"
                @change="onProvinceChange"
                @focus="fetchProvinces"
              >
                <option value="">Select a Province</option>
                <option
                  v-for="(province, index) in provinces"
                  :key="index"
                  :value="province.name"
                >
                  {{ province.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="townDropdown"
                class="form-control"
                v-model="selectedTown"
                @change="onTownChange"
                v-if="towns.length > 0"
              >
                <option value="">Select a Town</option>
                <option
                  v-for="(town, index) in towns"
                  :key="index"
                  :value="town.name"
                >
                  {{ town.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <select
                id="barangayDropdown"
                class="form-control"
                v-model="selectedBarangay"
                @change="Search()"
                v-if="barangays.length > 0"
              >
                <option value="">Select a Barangay</option>
                <option
                  v-for="(barangay, index) in barangays"
                  :key="index"
                  :value="barangay.name"
                >
                  {{ barangay.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="search-bar-container">
          <div class="input-group Search">
            <input
              type="search"
              class="form-control rounded"
              id="search_input"
              @keydown.enter="Search"
              v-model="searchInput"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
            <button
              type="button"
              class="btn btn-outline-primary ml-1"
              @click="Search"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Private Applicant</th>
              <th>Location</th>
              <th>No. of Trees</th>
              <th>Date Completed</th>
              <th>Completed by</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="paginatedAppointment.length === 0">
              <td colspan="11" class="text-center">No results found</td>
            </tr>
            <tr v-for="item in paginatedAppointment" :key="item.id">
              <td>{{ item.user.Fname }} {{ item.user.Lname }}</td>
              <td>{{ item.barangay }}, {{ item.town }}, {{ item.province }}</td>
              <td>{{ item.tree_num }}</td>
              <td>{{ item.cut_Date }}</td>
              <td>{{ item.admin.Fname }} {{ item.admin.Lname }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-controls">
        <button @click="previousPage" :disabled="currentPage === 1">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "../config";
export default {
  setup() {
    const CompletedPublic = ref([]);
    const Fromdate = ref("");
    const Todate = ref("");
    const searchInput = ref("");
    const itemsPerPage = 10;
    const currentPage = ref(1);
    const selectedProvinces = ref("");
    const selectedTown = ref("");
    const selectedBarangay = ref("");
    const provinces = ref([]);
    const towns = ref([]);
    const barangays = ref([]);

    const fetchCompletedPublic = async () => {
      try {
        const response = await axios.get(`${API_URL}/completedPublic`);
        CompletedPublic.value = response.data.CompletedPublic;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong while fetching the data!",
        });
      }
    };

    const Search = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/SearchQueryCompletedPublic`,
          {
            params: {
              query: searchInput.value,
              province: selectedProvinces.value,
              town: selectedTown.value,
              barangay: selectedBarangay.value,
              Fromdate: Fromdate.value,
              Todate: Todate.value,
            },
          }
        );
        CompletedPublic.value = response.data.SearchCompletedPublic;
      } catch (error) {}
    };

    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${API_URL}/provinces`);
        provinces.value = response.data.map((province) => ({
          name: province.name,
          code: province.code,
        }));
      } catch (error) {}
    };

    const fetchTown = async (provinceCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/provinces/${provinceCode}/municipalities`
        );
        towns.value = response.data.map((town) => ({
          name: town.name,
          code: town.code,
        }));
      } catch (error) {}
    };

    const fetchBarangays = async (townCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/municipalities/${townCode}/barangays`
        );
        barangays.value = response.data.map((barangay) => ({
          name: barangay.name,
        }));
      } catch (error) {}
    };

    const onProvinceChange = (event) => {
      const selectedProvinceName = event.target.value;
      const selectedProvince = provinces.value.find(
        (province) => province.name === selectedProvinceName
      );
      if (selectedProvince) {
        fetchTown(selectedProvince.code);
      }
      Search();
    };

    const onTownChange = (event) => {
      const selectedTownName = event.target.value;
      const selectedTownData = towns.value.find(
        (town) => town.name === selectedTownName
      );
      if (selectedTownData) {
        fetchBarangays(selectedTownData.code);
      }
      Search();
    };

    const paginatedAppointment = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return CompletedPublic.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(CompletedPublic.value.length / itemsPerPage);
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    onMounted(() => {
      fetchCompletedPublic();
      fetchProvinces();
    });
    return {
      CompletedPublic,
      Fromdate,
      Todate,
      Search,
      searchInput,
      paginatedAppointment,
      totalPages,
      previousPage,
      nextPage,
      currentPage,
      fetchProvinces,
      provinces,
      selectedProvinces,
      fetchTown,
      towns,
      selectedTown,
      fetchBarangays,
      barangays,
      selectedBarangay,
      onProvinceChange,
      onTownChange,
    };
  },
};
</script>

<style scoped>
.table thead th {
  background-color: #325f62;
  padding: 0.5rem;
  color: white;
  border: none;
}

.table th,
.table td {
  text-align: center;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dateLabelFrom {
  position: absolute;
  margin-left: -3rem;
  padding-top: 0.5rem;
}

.dateLabelTo {
  position: absolute;
  margin-left: -2rem;
  padding-top: 0.5rem;
}

.pagination-controls {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.pagination-controls button {
  margin: 0 0.5rem;
}

.pagination-controls span {
  margin: 0 0.5rem;
}

.page-item {
  display: inline-block;
  margin: 0 0.25rem;
}

.page-link {
  color: #007bff;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.page-link:hover {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-radius: 0.25rem;
}
</style>
