<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-10"></div>
      <div class="col-2">
        <div class="form-group d-flex align-items-center">
          <select
            id="ManageDropdown"
            class="form-control"
            v-model="selectedManage"
          >
            <option value="Not verified">Not verified</option>
            <option value="Verified">Verified</option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="selectedManage === 'Not verified'" class="row mb-10">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Registration Number</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="paginatedUser.length === 0">
              <td colspan="12" class="text-center">No results found</td>
            </tr>
            <tr v-for="user in paginatedUser" :key="user.id">
              <td>{{ user.Fname }}</td>
              <td>{{ user.Lname }}</td>
              <td>{{ user.regNumber }}</td>
              <td>{{ user.phone }}</td>
              <td>{{ user.barangay }}</td>
              <td>{{ user.email }}</td>
              <td>
                <button
                  @click="confirmApproveUser(user.id)"
                  class="btn btn-primary btn-sm mr-1"
                >
                  <i class="bi bi-check-lg"></i>
                </button>
                <button
                  @click="confirmDeclineUser(user.id)"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else-if="selectedManage === 'Verified'" class="row mb-10">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Registration Number</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="paginatedUser.length === 0">
              <td colspan="12" class="text-center">No results found</td>
            </tr>
            <tr v-for="user in paginatedUser" :key="user.id">
              <td>{{ user.Fname }}</td>
              <td>{{ user.Lname }}</td>
              <td>{{ user.regNumber }}</td>
              <td>{{ user.phone }}</td>
              <td>{{ user.barangay }}</td>
              <td>{{ user.email }}</td>
              <td>
                <button
                  @click="confirmDeclineUser(user.id)"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-controls">
      <button @click="previousPage" :disabled="currentPage === 1">
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "../config";

export default {
  name: "ManageUserComponent",
  setup() {
    const users = ref([]);
    const currentPage = ref(1);
    const itemsPerPage = 10;
    const selectedManage = ref("Not verified");

    const fetchUsers = async (page = 1) => {
      try {
        if (selectedManage.value === "Not verified") {
          const token = localStorage.getItem("token");
          const response = await axios.get(`${API_URL}/users`, {
            params: {
              status: "Not verified",
              page: page,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          users.value = response.data.users;
        } else {
          const response = await axios.get(`${API_URL}/verifiedUsers`, {
            params: {
              page: page,
            },
          });
          users.value = response.data.users;
        }
      } catch (error) {}
    };

    const approveUser = async (id) => {
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          `${API_URL}/users/${id}/approve`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        Swal.fire("Approved!", "The user has been approved.", "success");
        fetchUsers(currentPage.value);
      } catch (error) {
        Swal.fire("Error!", "There was an error approving the user.", "error");
      }
    };

    const declineUser = async (id) => {
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          `${API_URL}/users/${id}/decline`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        Swal.fire("Declined!", "The user has been declined.", "success");
        fetchUsers(currentPage.value);
      } catch (error) {
        Swal.fire("Error!", "There was an error declining the user.", "error");
      }
    };

    const confirmApproveUser = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to approve this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve it!",
      }).then((result) => {
        if (result.isConfirmed) {
          approveUser(id);
        }
      });
    };

    const confirmDeclineUser = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to decline this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, decline it!",
      }).then((result) => {
        if (result.isConfirmed) {
          declineUser(id);
        }
      });
    };

    const paginatedUser = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return users.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(users.value.length / itemsPerPage);
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    onMounted(() => {
      fetchUsers();
    });

    watch(selectedManage, () => {
      fetchUsers();
    });
    return {
      users,
      confirmApproveUser,
      confirmDeclineUser,
      fetchUsers,
      currentPage,
      totalPages,
      selectedManage,
      paginatedUser,
      previousPage,
      nextPage,
    };
  },
};
</script>

<style scoped>
.table thead th {
  background-color: #325f62;
  padding: 0.5rem;
  color: white;
  border: none;
}

.table th,
.table td {
  text-align: center;
}

.pagination-controls {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.pagination-controls button {
  margin: 0 0.5rem;
}

.pagination-controls span {
  margin: 0 0.5rem;
}

.page-item {
  display: inline-block;
  margin: 0 0.25rem;
}

.page-link {
  color: #007bff;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.page-link:hover {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-radius: 0.25rem;
}
</style>
