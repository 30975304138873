<template>
  <div class="app">
    <Topnav v-if="showTopnav" />

    <div class="content-container">
      <Sidebar v-if="showSidebar" />

      <main :class="{ 'no-sidebar': !showSidebar }">
        <router-view />
      </main>
    </div>
    <Footer v-if="showSidebar" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Sidebar from "./components/Sidebar.vue";
import Topnav from "./components/Topnav.vue";
import Footer from "./components/Footer.vue";

const route = useRoute();

const showSidebar = computed(() => {
  return !["/login", "/21171109365127536"].includes(route.path);
});

const showTopnav = computed(() => {
  return !["/login", "/21171109365127536"].includes(route.path);
});
</script>

<style lang="scss">
:root {
  --primary: #4ade80;
  --primary-alt: #22c55e;
  --grey: #64748b;
  --dark: #1e293b;
  --dark-alt: #334155;
  --light: #f1f5f9;
  --sidebar-width: 300px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira sans", sans-serif;
}

body {
  background: var(--light);
}

button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: var(--sidebar-width);
  background: var(--dark-alt);
}

main {
  flex: 1;
  padding: 2rem 2rem 0rem 2rem;
  background: var(--light);
  overflow-y: auto;
  display: flex;

  @media (max-width: 1024px) {
    padding-left: 6rem;
  }
}

main.no-sidebar {
  padding-left: 0;
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}

main > router-view {
  width: 100%;
  height: 100%;
}
</style>
