<template>
  <div class="container-fluid report-container">
    <div class="row mb-1" style="padding-left: 4cap">
      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <label class="dateLabelFrom">From:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Fromdate"
              @change="Search"
            />
          </div>
          <div class="col-2">
            <label class="dateLabelTo">To:</label>
            <input
              type="date"
              class="form-control"
              placeholder="Select Date"
              v-model="Todate"
              @change="Search"
            />
          </div>
          <div class="col-2">
            <select
              class="form-select form-control"
              v-model="Status"
              @change="Search"
            >
              <option value="">Tree Status</option>
              <option value="Standing">Standing</option>
              <option value="Snag">Snag</option>
              <option value="Cut">Cut</option>
            </select>
          </div>
          <div class="col-2">
            <select
              class="form-select form-control"
              v-model="Tagged"
              @change="Search"
            >
              <option value="">Tagged Trees</option>
              <option value="Tagged">Already Tagged</option>
              <option value="Untagged">Not yet Tagged</option>
            </select>
          </div>
          <div class="col-2">
            <select
              class="form-select form-control"
              v-model="Conservation"
              @change="Search"
            >
              <option value="">Conservation</option>
              <option value="Unclassified">Unclassified</option>
              <option value="Threatened">Threatened</option>
              <option value="Endangered">Endangered</option>
            </select>
          </div>
          <div class="col-2"></div>
        </div>
      </div>

      <div class="col-4 d-flex justify-content-end align-items-center">
        <div class="input-group Search">
          <input
            type="search"
            class="form-control rounded"
            id="search_input"
            @keydown.enter="Search"
            v-model="searchInput"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
          />
          <button
            type="button"
            class="btn btn-outline-primary ml-1"
            @click="Search"
          >
            Search
          </button>
        </div>

        <span
          @click="paginatedTrees.length === 0 ? null : printPreview()"
          class="material-icons button-icon ml-2"
          :style="{ color: paginatedTrees.length === 0 ? 'gray' : '' }"
          >picture_as_pdf</span
        >

        <span
          @click="paginatedTrees.length === 0 ? null : generateExcel()"
          class="material-icons button-icon ml-2"
          :style="{ color: paginatedTrees.length === 0 ? 'gray' : '' }"
          >table_view</span
        >
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <select
            id="provincesDropdown"
            class="form-control"
            v-model="selectedProvinces"
            @change="onProvinceChange"
            @focus="fetchProvinces"
          >
            <option value="">Select a Province</option>
            <option
              v-for="(province, index) in provinces"
              :key="index"
              :value="province.name"
            >
              {{ province.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <select
            id="townDropdown"
            class="form-control"
            v-model="selectedTown"
            @change="onTownChange"
            v-if="towns.length > 0"
          >
            <option value="">Select a Town</option>
            <option
              v-for="(town, index) in towns"
              :key="index"
              :value="town.name"
            >
              {{ town.name }}
            </option>
          </select>
        </div>

        <aside v-if="barangays.length > 0">
          <center><h3>List of Barangays</h3></center>
          <div class="menu">
            <a
              class="button"
              v-for="(barangay, index) in barangays"
              :key="index"
              @click="selectBarangay(barangay.name)"
            >
              <span class="text">{{ barangay.name }}</span>
            </a>
          </div>
        </aside>
      </div>

      <div class="col-10">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name Tag</th>
              <th>Common Name</th>
              <th>Conservation Status</th>
              <th>Status</th>
              <th>Location</th>
              <th>Date Registered</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="paginatedTrees.length === 0">
              <td colspan="12" class="text-center">No results found</td>
            </tr>
            <tr v-for="tree in paginatedTrees" :key="tree.id">
              <td>{{ tree.nameTag }}</td>
              <td>{{ tree.com_Name }}</td>
              <td>{{ tree.conserve_status }}</td>
              <td>{{ tree.Tree_status }}</td>
              <td>{{ tree.barangay }}, {{ tree.town }}, {{ tree.province }}</td>
              <td>{{ tree.date }}</td>
              <td>
                <div class="row">
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="viewLocation(tree.Lat, tree.Lng)"
                    >
                      <i class="fas fa-map-marker-alt"></i>
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="printQRCode(tree)"
                    >
                      <i class="fas fa-qrcode"></i>
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="openEditModal(tree)"
                          >Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-danger"
                          href="#"
                          @click="confirmDelete(tree)"
                          >Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="pagination-controls">
      <button @click="previousPage" :disabled="currentPage === 1">
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">
        Next
      </button>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      role="dialog"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">
              Edit Tree Information
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeEditModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveChanges">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="editnameTag">Name Tag</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editnameTag"
                      v-model="editTree.nameTag"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editComName">Common Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editComName"
                      v-model="editTree.com_Name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editSciName">Scientific Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editSciName"
                      v-model="editTree.sci_Name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editFamName">Family Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editFamName"
                      v-model="editTree.fam_Name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editOrigin">Origin</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editOrigin"
                      v-model="editTree.origin"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editConserveStat">Conservation Status</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.conserve_status"
                      required
                    >
                      <option value="Threatened">Threatened</option>
                      <option value="Endangered">Endangered</option>
                      <option value="Unclassified">Unclassified</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="editUses">Uses</label>
                    <input
                      type="text"
                      class="form-control"
                      id="editUses"
                      v-model="editTree.uses"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="editTreeStat">Tree Status</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.Tree_status"
                      required
                    >
                      <option value="Standing">Standing</option>
                      <option value="Snag">Snag</option>
                      <option value="Cut">Cut</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="editTaggingStat">Tagging Status</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.tagging_stat"
                      required
                    >
                      <option value="Tagged">Tagged</option>
                      <option value="Untagged">Untagged</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="editProtection">Protection</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.protection"
                      required
                    >
                      <option value="Normal">Normal</option>
                      <option value="Significant">Significant</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="editSpecies">Species</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.species"
                      required
                    >
                      <option value="Native">Native</option>
                      <option value="Exotic">Exotic</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="editisPresent">Present</label>
                    <select
                      class="form-select form-control"
                      v-model="editTree.isPresent"
                      required
                    >
                      <option value="Visible">Visible</option>
                      <option value="Not visible">Not Visible</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="closeEditModal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import { API_URL } from "../config";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

export default {
  name: "CardComponent",
  setup() {
    const router = useRouter();
    const combinedTrees = ref([]);
    const editTree = ref({});
    const currentPage = ref(1);
    const itemsPerPage = 10;
    const selectedProvinces = ref("");
    const selectedTown = ref("");
    const selectedBarangay = ref("");
    const provinces = ref([]);
    const towns = ref([]);
    const barangays = ref([]);
    const searchInput = ref("");
    const Fromdate = ref("");
    const Todate = ref("");
    const Status = ref("");
    const Tagged = ref("");
    const Conservation = ref("");
    const Fname = localStorage.getItem("Fname");
    const Lname = localStorage.getItem("Lname");

    const fetchTrees = async () => {
      try {
        const response = await axios.get(`${API_URL}/trees`);
        combinedTrees.value = response.data.trees;
        combinedTrees.value.forEach((tree) => (tree.showDropdown = false));
      } catch (error) {}
    };

    const Search = async () => {
      try {
        const response = await axios.get(`${API_URL}/searchquerybarangay`, {
          params: {
            query: searchInput.value,
            Fromdate: Fromdate.value,
            Todate: Todate.value,
            status: Status.value,
            tagged: Tagged.value,
            conservation: Conservation.value,
            province: selectedProvinces.value,
            town: selectedTown.value,
            barangay: selectedBarangay.value,
          },
        });
        combinedTrees.value = response.data.trees;
      } catch (error) {}
    };

    const fetchProvinces = async () => {
      try {
        const response = await axios.get(`${API_URL}/provinces`);
        provinces.value = response.data.map((province) => ({
          name: province.name,
          code: province.code,
        }));
      } catch (error) {}
    };

    const fetchTown = async (provinceCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/provinces/${provinceCode}/municipalities`
        );
        towns.value = response.data.map((town) => ({
          name: town.name,
          code: town.code,
        }));
      } catch (error) {}
    };

    const fetchBarangays = async (townCode) => {
      try {
        const response = await axios.get(
          `${API_URL}/municipalities/${townCode}/barangays`
        );
        barangays.value = response.data.map((barangay) => ({
          name: barangay.name,
        }));
      } catch (error) {}
    };

    const onProvinceChange = (event) => {
      const selectedProvinceName = event.target.value;
      const selectedProvince = provinces.value.find(
        (province) => province.name === selectedProvinceName
      );
      if (selectedProvince) {
        fetchTown(selectedProvince.code);
      }
      Search();
    };

    const onTownChange = (event) => {
      const selectedTownName = event.target.value;
      const selectedTownData = towns.value.find(
        (town) => town.name === selectedTownName
      );
      if (selectedTownData) {
        fetchBarangays(selectedTownData.code);
      }
      Search();
    };

    const selectBarangay = (barangayName) => {
      selectedBarangay.value = barangayName;
      Search();
    };

    const saveChanges = async () => {
      try {
        if (Fname) {
          editTree.value.Fname = Fname;
        }
        if (Lname) {
          editTree.value.Lname = Lname;
        }
        await axios.put(
          `${API_URL}/editTree/${editTree.value.id}`,
          editTree.value
        );
        const index = combinedTrees.value.findIndex(
          (tree) => tree.id === editTree.value.id
        );
        if (index !== -1) {
          combinedTrees.value[index] = { ...editTree.value };
        }
        closeEditModal();
        successEdit();
      } catch (error) {}
    };

    function successEdit() {
      Swal.fire({
        title: "Changes saved successfully!",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }

    const deleteTree = async (tree) => {
      if (Fname) {
        editTree.value.Fname = Fname;
      }
      if (Lname) {
        editTree.value.Lname = Lname;
      }
      try {
        await axios.put(`${API_URL}/deleteTree/${tree.id}`, editTree.value);
        fetchTrees();
      } catch (error) {}
    };

    const confirmDelete = (tree) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteTree(tree);
          Swal.fire({
            title: "Tagged tree has beed deleted!!",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      });
    };

    const viewLocation = (lat, lng) => {
      router.push({ path: "/home", query: { lat, lng } });
    };

    const printQRCode = async (tree) => {
      try {
        Swal.fire({
          title: "Loading",
          text: "Please wait while the PDF is being generated...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const QR_Image = new Image();
        QR_Image.crossOrigin = "Anonymous";
        QR_Image.src = `${API_URL}/QR-image/${tree.qr_pic}`;

        const Tree_Image = new Image();
        Tree_Image.crossOrigin = "Anonymous";
        Tree_Image.src = `${API_URL}/tree-image/${tree.tree_pic}`;

        const Denrlogo_Image = new Image();
        Denrlogo_Image.src = require("@/assets/DENR-Logo.png");

        const Mapunologo_Image = new Image();
        Mapunologo_Image.src = require("@/assets/MapunologoQR.png");
        await new Promise((resolve, reject) => {
          QR_Image.onload = resolve;
          QR_Image.onerror = reject;
        });

        await new Promise((resolve, reject) => {
          Tree_Image.onload = resolve;
          Tree_Image.onerror = reject;
        });

        const doc = new jsPDF({ compressPdf: true });

        const denrlogoCanvas = document.createElement("canvas");
        const denrlogoCtx = denrlogoCanvas.getContext("2d");
        denrlogoCanvas.width = Denrlogo_Image.width;
        denrlogoCanvas.height = Denrlogo_Image.height;
        denrlogoCtx.drawImage(Denrlogo_Image, 0, 0);
        const Denrlogo = denrlogoCanvas.toDataURL("image/png");

        const mapunologoCanvas = document.createElement("canvas");
        const mapunologoCtx = mapunologoCanvas.getContext("2d");
        mapunologoCanvas.width = Mapunologo_Image.width;
        mapunologoCanvas.height = Mapunologo_Image.height;
        mapunologoCtx.drawImage(Mapunologo_Image, 0, 0);
        const Mapunologo = mapunologoCanvas.toDataURL("image/jpeg");

        const qrCanvas = document.createElement("canvas");
        const qrCtx = qrCanvas.getContext("2d");
        qrCanvas.width = QR_Image.width;
        qrCanvas.height = QR_Image.height;
        qrCtx.drawImage(QR_Image, 0, 0);
        const QR = qrCanvas.toDataURL("image/png");

        const treeCanvas = document.createElement("canvas");
        const treeCtx = treeCanvas.getContext("2d");
        treeCanvas.width = Tree_Image.width;
        treeCanvas.height = Tree_Image.height;
        treeCtx.drawImage(Tree_Image, 0, 0);
        const Tree = treeCanvas.toDataURL("image/jpeg");

        const pageWidth = doc.internal.pageSize.getWidth();
        const imageWidth = 40;
        const totalImageWidth = 2 * imageWidth;
        const marginX = (pageWidth - totalImageWidth) / 2;
        const center = pageWidth / 2;

        doc.addImage(Denrlogo, "PNG", marginX - 4, 10, imageWidth, 40);
        doc.addImage(
          Mapunologo,
          "JPEG",
          marginX + imageWidth,
          18,
          imageWidth + 5,
          20
        );
        doc.setFont(undefined, "bold");
        doc.setFontSize(16);
        doc.text(
          "Department of Environment and Natural Resources",
          center,
          53,
          { align: "center" }
        );
        doc.setFont(undefined, "normal");
        doc.text("Ramos East, San Isidro, Isabela, Philippines", center, 60, {
          align: "center",
        });
        doc.setFont(undefined, "bold");
        doc.setFontSize(20);
        doc.text(`${tree.nameTag}`, center, 73, { align: "center" });
        doc.setFont(undefined, "normal");
        doc.setFontSize(16);
        doc.addImage(QR, "PNG", 13, 80, 90, 100);
        doc.addImage(Tree, "JPEG", 110, 80, 90, 100);
        doc.text(
          `Location: ${tree.barangay},  ${tree.town}, ${tree.province}`,
          center,
          190,
          { align: "center" }
        );

        if (tree.protection === "Significant") {
          doc.setFont(undefined, "bold");
          doc.setFontSize(25);
          doc.text("Please do not destroy or hurt this tree!", center, 205, {
            align: "center",
          });
          doc.setFont(undefined, "bold");
          doc.setFontSize(13);
          doc.text("Download MAPUNO app on Playstore", center, 215, {
            align: "center",
          });
        } else {
          doc.setFont(undefined, "bold");
          doc.setFontSize(13);
          doc.text("Download MAPUNO app on Playstore", center, 200, {
            align: "center",
          });
        }

        doc.save(`${tree.id}_QRCode.pdf`);

        Swal.close();

        Swal.fire({
          icon: "success",
          title: "PDF Generated",
          text: "The QR code PDF has been successfully generated!",
        });
      } catch (error) {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error generating the QR code PDF. Please try again.",
        });
      }
    };

    const printPreview = async () => {
      try {
        Swal.fire({
          title: "Loading",
          text: "Please wait while the PDFs are being generated...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const trees = combinedTrees.value;
        const setsPerPage = 1;
        const doc = new jsPDF({ compressPdf: true });

        for (let i = 0; i < trees.length; i++) {
          const tree = trees[i];

          const QR_Image = new Image();
          QR_Image.crossOrigin = "Anonymous";
          QR_Image.src = `${API_URL}/QR-image/${tree.qr_pic}`;

          const Tree_Image = new Image();
          Tree_Image.crossOrigin = "Anonymous";
          Tree_Image.src = `${API_URL}/tree-image/${tree.tree_pic}`;

          const Denrlogo_Image = new Image();
          Denrlogo_Image.src = require("@/assets/DENR-Logo.png");

          const Mapunologo_Image = new Image();
          Mapunologo_Image.src = require("@/assets/MapunologoQR.png");

          await new Promise((resolve, reject) => {
            QR_Image.onload = resolve;
            QR_Image.onerror = reject;
          });

          await new Promise((resolve, reject) => {
            Tree_Image.onload = resolve;
            Tree_Image.onerror = reject;
          });

          const denrlogoCanvas = document.createElement("canvas");
          const denrlogoCtx = denrlogoCanvas.getContext("2d");
          denrlogoCanvas.width = Denrlogo_Image.width;
          denrlogoCanvas.height = Denrlogo_Image.height;
          denrlogoCtx.drawImage(Denrlogo_Image, 0, 0);
          const Denrlogo = denrlogoCanvas.toDataURL("image/png");

          const mapunologoCanvas = document.createElement("canvas");
          const mapunologoCtx = mapunologoCanvas.getContext("2d");
          mapunologoCanvas.width = Mapunologo_Image.width;
          mapunologoCanvas.height = Mapunologo_Image.height;
          mapunologoCtx.drawImage(Mapunologo_Image, 0, 0);
          const Mapunologo = mapunologoCanvas.toDataURL("image/jpeg");

          const qrCanvas = document.createElement("canvas");
          const qrCtx = qrCanvas.getContext("2d");
          qrCanvas.width = QR_Image.width;
          qrCanvas.height = QR_Image.height;
          qrCtx.drawImage(QR_Image, 0, 0);
          const QR = qrCanvas.toDataURL("image/png");

          const treeCanvas = document.createElement("canvas");
          const treeCtx = treeCanvas.getContext("2d");
          treeCanvas.width = Tree_Image.width;
          treeCanvas.height = Tree_Image.height;
          treeCtx.drawImage(Tree_Image, 0, 0);
          const Tree = treeCanvas.toDataURL("image/jpeg");

          const pageWidth = doc.internal.pageSize.getWidth();
          const imageWidth = 40;
          const totalImageWidth = 2 * imageWidth;
          const marginX = (pageWidth - totalImageWidth) / 2;
          const center = pageWidth / 2;

          doc.addImage(Denrlogo, "PNG", marginX - 4, 10, imageWidth, 40);
          doc.addImage(
            Mapunologo,
            "JPEG",
            marginX + imageWidth,
            18,
            imageWidth + 5,
            20
          );
          doc.setFont(undefined, "bold");
          doc.setFontSize(16);
          doc.text(
            "Department of Environment and Natural Resources",
            center,
            53,
            { align: "center" }
          );
          doc.setFont(undefined, "normal");
          doc.text("Ramos East, San Isidro, Isabela, Philippines", center, 60, {
            align: "center",
          });
          doc.setFont(undefined, "bold");
          doc.setFontSize(20);
          doc.text(`${tree.nameTag}`, center, 73, {
            align: "center",
          });
          doc.setFont(undefined, "normal");
          doc.setFontSize(16);
          doc.addImage(QR, "PNG", 13, 80, 90, 100);
          doc.addImage(Tree, "JPEG", 110, 80, 90, 100);
          doc.text(
            `Location: ${tree.barangay},  ${tree.town}, ${tree.province}`,
            center,
            190,
            {
              align: "center",
            }
          );

          if (tree.protection === "Significant") {
            doc.setFont(undefined, "bold");
            doc.setFontSize(25);
            doc.text("Please do not destroy or hurt this tree!", center, 205, {
              align: "center",
            });
            doc.setFont(undefined, "bold");
            doc.setFontSize(13);
            doc.text("Download MAPUNO app on Playstore", center, 215, {
              align: "center",
            });
            doc.setFontSize(16);
          } else {
            doc.setFont(undefined, "bold");
            doc.setFontSize(13);
            doc.text("Download MAPUNO app on Playstore", center, 200, {
              align: "center",
            });
            doc.setFontSize(16);
          }

          if ((i + 1) % setsPerPage === 0 && i + 1 < trees.length) {
            doc.addPage();
          }
        }

        doc.save(`Print_preview.pdf`);
        Swal.close();

        Swal.fire({
          icon: "success",
          title: "PDF Generated",
          text: "The PDF has been successfully generated!",
        });
      } catch (error) {
        Swal.close();

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error generating the PDFs. Please try again.",
        });
      }
    };

    const generateExcel = () => {
      const Excel = combinedTrees.value;
      const header = [
        [
          (Fromdate.value === "" && Todate.value === ""
            ? "No Selected Date"
            : "") +
            (Fromdate.value === "" ? "" : "Date From: ") +
            Fromdate.value +
            (Todate.value === "" ? "" : "To: ") +
            Todate.value,
        ],
        [
          (selectedProvinces.value === ""
            ? "No Selected Location"
            : "Location: ") +
            selectedProvinces.value +
            (selectedTown.value === "" ? " " : ",") +
            selectedTown.value +
            (selectedBarangay.value === "" ? " " : ",") +
            selectedBarangay.value,
        ],
        ["", ""],
        "Tree No.",
        "Species",
        "Tree Location (Latitude)",
        "Tree Location (Longitude)",
        "Location",
      ];

      const excelData = Excel.map((item) => ({
        "No Selected Date": "",
        "No Selected Location": "",
        Space: "",
        "Tree No.": item.id,
        Species: item.com_Name,
        "Tree Location (Latitude)": item.Lat,
        "Tree Location (Longitude)": item.Lng,
        Location: item.province + "," + item.town + "," + item.barangay,
      }));

      const worksheetData = [
        header,
        ...excelData.map((item) => Object.values(item)),
      ];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Tally Sheet.xlsx");
    };

    const paginatedTrees = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return combinedTrees.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(combinedTrees.value.length / itemsPerPage);
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value += 1;
      }
    };

    const openEditModal = (tree) => {
      editTree.value = { ...tree };
      $("#editModal").modal("show");
    };

    const closeEditModal = () => {
      editTree.value = {};
      $("#editModal").modal("hide");
    };

    onMounted(() => {
      fetchTrees();
      fetchProvinces();
    });

    return {
      combinedTrees,
      editTree,
      currentPage,
      totalPages,
      paginatedTrees,
      fetchTrees,
      deleteTree,
      confirmDelete,
      viewLocation,
      printQRCode,
      openEditModal,
      closeEditModal,
      saveChanges,
      previousPage,
      nextPage,
      printPreview,
      Search,
      searchInput,
      Fromdate,
      Todate,
      Status,
      Tagged,
      generateExcel,
      Conservation,
      Fname,
      Lname,
      fetchProvinces,
      provinces,
      selectedProvinces,
      fetchTown,
      towns,
      selectedTown,
      fetchBarangays,
      barangays,
      selectedBarangay,
      onProvinceChange,
      onTownChange,
      selectBarangay,
    };
  },
};
</script>

<style scoped>
.menu {
  max-height: calc(83vh - 250px);
  overflow-y: auto;
}

.report-container {
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.dateLabelFrom {
  position: absolute;
  margin-left: -3rem;
  padding-top: 0.5rem;
}

.dateLabelTo {
  position: absolute;
  margin-left: -1.5rem;
  padding-top: 0.5rem;
}

.modal-header {
  background-color: #325f62;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.modal-title {
  font-size: 1.25rem;
  color: white;
  text-align: center;
}

.modal-header .close {
  color: white;
}

.modal-body p {
  font-size: 0.875rem;
  font-family: Arial, sans-serif;
  font-weight: normal;
}

.modal-body {
  font-size: 1rem;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  margin-right: 8px;
}

.button-icon {
  font-size: 35px;
}

aside {
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
}

.table thead th {
  background-color: #325f62;
  padding: 0.5rem;
  color: white;
  border: none;
}

.table th,
.table td {
  text-align: center;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #000;
  margin-bottom: 10px;
  cursor: pointer;
}

.button:hover {
  background-color: #e2e6ea;
  border-radius: 4px;
}

.text {
  margin-left: 10px;
}

h3 {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #343a40;
}

.material-icons {
  cursor: pointer;
}

.mt-auto {
  margin-top: auto;
}

.btn {
  padding: 4px 8px;
  font-size: 12px;
}

.btn-secondary {
  margin-left: 2px;
}

.pagination-controls {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.pagination-controls button {
  margin: 0 0.5rem;
}

.pagination-controls span {
  margin: 0 0.5rem;
}

.page-item {
  display: inline-block;
  margin: 0 0.25rem;
}

.page-link {
  color: #007bff;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.page-link:hover {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-radius: 0.25rem;
}
</style>
