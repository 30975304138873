<template>
  <div class="chart-container">
    <PolarAreaChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { PolarArea } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  ArcElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PolarAreaController,
  RadialLinearScale,
  PointElement,
  ArcElement
);

export default defineComponent({
  name: "EveryTreeCount",
  components: { PolarAreaChart: PolarArea },
  props: {
    comNameCounts: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartData = computed(() => {
      const comNameCountsArray = Array.isArray(props.comNameCounts)
        ? props.comNameCounts
        : [];

      return {
        labels: comNameCountsArray.map((item) => item.com_Name),
        datasets: [
          {
            data: comNameCountsArray.map((item) => item.count),
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
              "rgba(255, 206, 86, 0.8)",
              "rgba(75, 192, 192, 0.8)",
              "rgba(153, 102, 255, 0.8)",
              "rgba(255, 159, 64, 0.8)",
              "rgba(255, 206, 86, 0.8)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 2,
          },
        ],
      };
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          titleFont: {
            size: 14,
            family: "Roboto, sans-serif",
            weight: "600",
          },
          bodyFont: {
            size: 12,
            family: "Roboto, sans-serif",
          },
          padding: 12,
          borderColor: "#666666",
          borderWidth: 1,
        },
        title: {
          display: true,
          text: "Tree Species",
          font: {
            size: 22,
            weight: "700",
            family: "Roboto, sans-serif",
          },
          color: "#222222",
          padding: {
            top: 15,
            bottom: 15,
          },
        },
      },
    };
    return {
      chartData,
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  height: 729px;
  position: relative;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 20px;
}

.chart-container canvas {
  height: 100% !important;
}
</style>
