<template>
  <div class="chart-container">
    <BarChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default defineComponent({
  name: "ScanCountChart",
  components: { BarChart: Bar },
  props: {
    scanCounts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const chartData = computed(() => {
      const labels = props.scanCounts.map((item) => item.nameTag);
      const data = props.scanCounts.map((item) => item.scanCount);

      return {
        labels,
        datasets: [
          {
            label: "Scan Counts",
            data,
            backgroundColor: "rgba(54, 162, 235, 0.8)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            borderRadius: 8,
            hoverBackgroundColor: "rgba(54, 162, 235, 1)",
          },
        ],
      };
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: "y",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          titleFont: {
            size: 14,
            family: "Roboto, sans-serif",
            weight: "600",
          },
          bodyFont: {
            size: 12,
            family: "Roboto, sans-serif",
          },
          padding: 12,
          borderColor: "#666666",
          borderWidth: 1,
        },
        title: {
          display: true,
          text: "Most Scanned Trees",
          position: "top",
          padding: {
            top: 15,
            bottom: 15,
          },
          font: {
            size: 22,
            weight: "700",
            family: "Roboto, sans-serif",
          },
          color: "#222222",
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          display: false,
          grid: {
            color: "#e0e0e0",
            borderColor: "#e0e0e0",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Name Tags",
            font: {
              size: 14,
              family: "Roboto, sans-serif",
              weight: "600",
            },
            color: "#555555",
          },
          grid: {
            display: false,
          },
        },
      },
      animation: {
        duration: 1000,
        easing: "easeInOutQuad",
      },
    };

    return {
      chartData,
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  margin: auto;
  height: 800px;
  position: relative;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 20px;
}

.chart-container canvas {
  height: 100%;
}
</style>
