<template>
  <div class="container-fluid">
    <div v-if="isGovernmentRoute">
      <GovernmentNav />
    </div>
    <div v-else-if="isPrivateRoute">
      <PrivateNav />
    </div>
    <div class="row no-gutters">
      <div class="col">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import GovernmentNav from "@/components/GovernmentNav.vue";
import PrivateNav from "@/components/PublicNav.vue";

export default {
  components: {
    GovernmentNav,
    PrivateNav,
  },
  setup() {
    const route = useRoute();
    const isGovernmentRoute = computed(
      () =>
        route.path.includes("/treecuttingappointment/pendingGovernment") ||
        route.path.includes("/treecuttingappointment/scheduledGovernment") ||
        route.path.includes("/treecuttingappointment/incompleteGovernment") ||
        route.path.includes("/treecuttingappointment/completedGovernment") ||
        route.path.includes("/treecuttingappointment/overdueGovernment")
    );

    const isPrivateRoute = computed(
      () =>
        route.path.includes("/treecuttingappointment/pendingPublic") ||
        route.path.includes("/treecuttingappointment/scheduledPublic") ||
        route.path.includes("/treecuttingappointment/incompletePublic") ||
        route.path.includes("/treecuttingappointment/completedPublic") ||
        route.path.includes("/treecuttingappointment/overduePublic")
    );

    return {
      isGovernmentRoute,
      isPrivateRoute,
    };
  },
};
</script>
