<template>
  <div class="chart-container">
    <LineChart :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

export default defineComponent({
  name: "MonthlyTaggedChart",
  components: {
    LineChart: Line,
  },
  props: {
    monthlyCounts: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const chartData = computed(() => {
      const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const data = labels.map((month, index) => {
        const yearMonth = `2024-${String(index + 1).padStart(2, "0")}`;
        return props.monthlyCounts[yearMonth] || 0;
      });

      return {
        labels,
        datasets: [
          {
            label: "Monthly Tagged Trees",
            data,
            borderColor: "rgba(50, 95, 98, 1)",
            backgroundColor: "rgba(50, 95, 98, 0.2)",
            borderWidth: 2,
            pointBackgroundColor: "rgba(50, 95, 98, 1)",
            pointBorderColor: "#ffffff",
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            tension: 0.4,
          },
        ],
      };
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              size: 14,
              family: "Roboto, sans-serif",
            },
          },
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          titleFont: {
            size: 14,
            family: "Roboto, sans-serif",
            weight: "600",
          },
          bodyFont: {
            size: 12,
            family: "Roboto, sans-serif",
          },
          padding: 12,
          borderColor: "#666666",
          borderWidth: 1,
        },
        title: {
          display: true,
          text: "Monthly Tagged Trees",
          position: "top",
          padding: {
            top: 15,
            bottom: 15,
          },
          font: {
            size: 22,
            weight: "700",
            family: "Roboto, sans-serif",
          },
          color: "#222222",
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Month",
            color: "#000000",
            font: {
              size: 14,
              family: "Roboto, sans-serif",
              weight: "600",
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: "Number of Trees",
            color: "#000000",
            font: {
              size: 14,
              family: "Roboto, sans-serif",
              weight: "600",
            },
          },
          grid: {
            color: "#e0e0e0",
            borderColor: "#e0e0e0",
          },
          beginAtZero: true,
        },
      },
      animation: {
        duration: 1000,
        easing: "easeInOutQuad",
      },
    };

    return {
      chartData,
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  margin: auto;
  height: 800px;
  position: relative;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 20px;
}

.chart-container canvas {
  height: 100%;
}
</style>
