import { createApp } from "vue";
import App from "./App.vue";
import Axios from "axios";
import router from "./router";

const app = createApp(App);

// Set default base URL for all requests
Axios.defaults.baseURL = "https://www.mapuno.io/api/auth";

// Add Axios interceptor to attach token to each request
Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

app.config.globalProperties.$http = Axios;
app.config.globalProperties.$axios = Axios;

app.use(router);
app.mount("#app");
